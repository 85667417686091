import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store';
import * as serviceWorker from "./serviceWorker";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.4.0";
import "assets/demo/demo.css?v=1.4.0";
import "assets/demo/react-demo.css?v=1.4.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.4.0";
// pages

import DropdownWhiteNavbar from "components/Navbars/DropdownWhiteNavbar.js";
import Whatsapp from "views/presentation-sections/Whatsapp.js"
import FooterBlack from "components/Footers/FooterBlack.js"
import Tramites from "views/Tramites.js";
import Presentation from "views/Presentation.js";
import Cotizador from "views/Cotizador2/CotizadorW.js"
import ClientesCorporativos from "views/Cotizador2/CotizadorW.js"
import Solicitudes from "views/Solicitudes.js";
import Transparencias from "views/Transparencia/Transpa.js";
import TramitesServicios from "views/tramites-sections/TramitesServicios";
import PoliticasDatosPersonales from "views/PoliticasDatosPersonales";
import TerminoCondiciones from "views/TerminosCondiciones";
import MapaSitio from "views/MapaSitio";
import PoliticasDerechosAutor from "views/PoliticasDerechosAutor";
import PoliticasPrivacidadWeb from "views/PoliticasPrivacidadWeb";
import CertificadoAccesibilidad from "views/CertificadoAccesibilidad";
import Notario from "views/Notario.js";
import Informacion from "views/Informacion.js";
import Procesos from "views/Procesos.js";
import Normativa from "views/Normativa.js";
import Tarifas from "views/Tarifas.js";
import Formatos from "views/Formato.js";
import Ninos from "views/Ninos.js";
import Mujeres from "views/Mujeres.js";
import Otrosin from "views/Otrosin.js";
import AfectarPublico from "views/AfectarPublico.js";
import GestionDocumental from "views/GestionDocumental.js"
import ClasificadaReservada from "views/ClasificadaReservada.js"
import GestionInformacion from "views/GestionInformacion.js"
import RetencionDocumental from "views/RetencionDocumental.js"
import RegistroCivil from "views/RegistroCivil.js"
import Participa from "views/menu-participa/Participa.js";
import Diagnostico from "views/menu-participa/diagnostico-identificacion-problemas.js";
import Planeacion from "views/menu-participa/planeacion-presupuesto-participativo.js";
import Rendicion from "views/menu-participa/rendicion-cuenta.js";
import Consulta from "views/menu-participa/consulta-ciudadana";
import Control from "views/menu-participa/control-social";
import TemasInteres from "views/menu-participa/temas-interes.js"
import Colaboracion from "views/menu-participa/colaboracion-innovacion-abierta";
import ZonaPagos from "views/ZonaPagos.js"
import Organigrama from "views/Organigrama.js" 
import MapaProcesos from "views/MapaProcesos.js"
import Protocolo from "views/Protocolo.js"
import Decisiones from "views/Decisiones.js"
import Enlaces from "views/Enlaces.js"
// others

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <div className='dark-mode'>
        <DropdownWhiteNavbar/>
        <Routes>
          <Route
            path="/formato"
            element={<Formatos />}
          />
          <Route
            path="/tarifa"
            element={<Tarifas />}
          />
          <Route
            path="/notario"
            element={<Notario />}
          />
          <Route
            path="/normativa"
            element={<Normativa />}
          />
          <Route
            path="/procesos"
            element={<Procesos />}
          />
          <Route
            path="/informacion"
            element={<Informacion />}
          />
          <Route
            path="/PoliticasDatosPersonales"
            element={<PoliticasDatosPersonales />}
          />
          <Route
            path="/PoliticasDerechosAutor"
            element={<PoliticasDerechosAutor />}
          />
          <Route
            path="/PoliticasPrivacidadWeb"
            element={<PoliticasPrivacidadWeb />}
          />
          <Route
            path="/CertificadoAccesibilidad"
            element={<CertificadoAccesibilidad />}
          />
          <Route
            path="/TerminosCondiciones"
            element={<TerminoCondiciones />}
          />
          <Route
            path="/MapaSitio"
            element={<MapaSitio />}
          />
          <Route
            path="/participa" 
            element={<Participa/>}
          />
          <Route
            path="/participacion-para-el-diagnostico-de-necesidades-e-identificacion-de-problemas" 
            element={<Diagnostico/>}
          />
          <Route
            path="/planeacion-y-presupuesto-participativo" 
            element={<Planeacion/>}
          />
          <Route
            path="/consulta-ciudadana" 
            element={<Consulta/>}
          />
          <Route
            path="/rendicion-de-cuentas" 
            element={<Rendicion/>}
          />
          <Route
            path="/control-social" 
            element={<Control/>}
          />
          <Route
            path="/colaboracion-e-innovacion-abierta" 
            element={<Colaboracion/>}
          />
          <Route
            path="/temas-interes" 
            element={<TemasInteres/>}
          />
          <Route
            path="/transparencia/:modalNombre"
            element={<Transparencias />}
          />
          <Route
            path="/peticiones"
            element={<Solicitudes />}
          />
          <Route
            path="/cotizador"
            element={<Cotizador />}
          />
          <Route
            path="/clientescorporativos"
            element={<ClientesCorporativos />}
          />
          <Route
            path="/tramite"
            element={<Tramites />}
          />
          <Route
            path="/servicios"
            element={<TramitesServicios />}
          />
          <Route
            path="/ClasificadaReservada"
            element={<ClasificadaReservada />}
          />
          <Route
            path="/GestionDocumental"
            element={<GestionDocumental />}
          />
          <Route
            path="/GestionInformacion"
            element={<GestionInformacion />}
          />
          <Route
            path="/RetencionDocumental"
            element={<RetencionDocumental />}
          />
          <Route
            path="/AfectarPublico"
            element={<AfectarPublico />}
          />
          <Route
            path="/RegistroCivil"
            element={<RegistroCivil />}
          />
          <Route
            path="/Ninos"
            element={<Ninos />}
          />
          <Route
            path="/Mujeres"
            element={<Mujeres />}
          />
          <Route
            path="/Otrosin"
            element={<Otrosin />}
          />
          <Route
            path="/ZonaPagos"
            element={<ZonaPagos />}
          />
          <Route
            path="/Organigrama"
            element={<Organigrama />}
          />
          <Route
            path="/MapaProcesos"
            element={<MapaProcesos />}
          />
          <Route
            path="/Protocolo"
            element={<Protocolo />}
          />
          <Route
            path="/Decisiones"
            element={<Decisiones />}
          />
          <Route
            path="/Enlaces"
            element={<Enlaces />}
          />
          <Route
            path="/"
            element={<Presentation />}
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Whatsapp />
        <FooterBlack />
      </div>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister()
