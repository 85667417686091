import React from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components
import { Container, 
    Row, 
    Col,
    Card,
    CardBody,
    CardImg,
    CardText
} from "reactstrap";
// core components

// sections for this page
import PresentationHeader from "components/Headers/PresentationHeader2.js";
// import Politica from "./Notario/Notario.js";


function Notario() {
  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // initialise Rellax for this page
    if (window.innerWidth >= 991) {
      setTimeout(function () {
        new Rellax(".rellax", {
          center: true,
        });
      }, 5000);
      new Rellax(".rellax-header");
      new Rellax(".rellax-text");
    }

    // we need to add a script for the github buttons
    let script = document.createElement("script");
    script.src = "https://buttons.github.io/buttons.js";
    script.id = "github-buttons-script-id";
    document.body.appendChild(script);

    return function cleanup() {
      document.body.classList.remove("tramites-page");
      document.body.classList.remove("sidebar-collapse");

      // we need to remove the script when we change the page
      script.parentNode.removeChild(script);
    };
  });
  return (
    <>
      <div className="wrapper">
        <PresentationHeader titulo="Biografía del Notario"/>
        <div id= "servicio" style={{backgroundColor: "#f9f9f9"}}>
          <Container className="fluid">
            <Row>
              <Col md="12">
                <Card style={{marginTop:"2%"}}>
                  <CardBody>
                    <Row>
                      <Col md ="4">
                        <Card style={{borderRadius: "15px"}}>
                          <CardImg
                            alt="Imagen representiva del logo del notario"
                            src={require("assets/img/Notaria/avatar.jpg")}
                            top
                          ></CardImg>
                          <CardBody>
                            <CardText className="text-center" >
                              <b>HOLMES RAFAEL CARDONA MONTOYA</b>
                            </CardText>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="8">
                        <Row>
                          <Col md="5">
                            <Card style={{borderRadius: "15px"}}>
                              <CardBody>
                                <h5><b style={{color: "#bd0b0e"}}>País: </b> Colombia</h5>
                                <h5><b style={{color: "#bd0b0e"}}>Departamento: </b> Valle del Cauca</h5>
                                <h5><b style={{color: "#bd0b0e"}}>Ciudad:  </b> Cali</h5>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col md="7">
                            <Card style={{borderRadius: "15px"}}>
                              <CardBody>
                                <h5><b style={{color: "#bd0b0e"}}>Correo: </b>racardona@notaria21cali.com </h5>
                                <h5><b style={{color: "#bd0b0e"}}>Teléfono: </b>+57 (2) 3303697 ext. 102 </h5>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <Card style={{borderRadius: "15px"}}>
                              <CardBody>
                                <h4><b style={{color: "#bd0b0e"}}>Formación Académica y Experiencia Laboral</b></h4>
                                <h5 className="text-justify">
                                <b>FORMACION ACADEMICA</b>
                                <br/><b>UNIVERSITARIOS</b>
                                <br/>•	FUNDACION GENERAL UNIVERSIDAD DE SALAMANCA : TRIGESIMO CURSO DE    ESPECIALIZACION EN DERECHO EN LA ESPECIALIDAD DE DERECHO NOTARIAL   (PARTE PRESENCIAL),  16 ENERO AL 1 FEBRERO 2012
                                <br/>•	UNIVERSIDAD SANTIAGO DE CALI: ESPECIALIZACION EN DERECHO DE FAMILIA
                                <br/>•	UNIVERSIDAD EXTERNADO DE  COLOMBIA: ESPECIALIZACION EN  DERECHO NOTARIAL Y REGISTRAL
                                <br/>•	UNIVERSIDAD NACIONAL DE COLOMBIA: ESPECIALIZACION EN INSTITUCIONES JURIDICO-POLÍTICAS Y  DERECHO PUBLICO
                                <br/>•	UNIVERSIDAD SANTIAGO DE CALI : PREGRADO COMO ABOGADO
                                <br/><b>EXPERIENCIA LABORAL Y PROFESIONAL</b>
                                <br/>•	NOTARIA  21: Notario desde agosto de 1997 hasta la fecha
                                <br/><b>EXPERIENCIA ACADEMICA</b>
                                <br/>•	UNIVERSIDAD SANTIAGO DE CALI : Docente desde 1995 hasta la fecha
                                <br/>Asignaturas: Derechos Humanos, Derecho Notarial y Registral, Derecho Urbanístico
                                </h5>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <Card style={{borderRadius: "15px"}}>
                              <CardBody>
                                <h4><b style={{color: "#bd0b0e"}}>Estudios, Investigaciones y otras publicaciones</b></h4>
                                <h5 className="text-justify">“EL NOTARIADO COLOMBIANO, AGENTE PROTECTOR DE LOS DERECHOS DE LA PERSONA HUMANA”; EDITADO POR: SUPERINTENDENCIA DE NOTARIADO Y REGISTRO</h5>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Row>
                  <Card style={{borderRadius: "15px"}}>
                    <CardBody>
                      <center><h4><b style={{color: "#bd0b0e"}}>Nuestros Colaboradores</b></h4></center>
                      <table id="tablePreview" className="table  table-sm table-bordered">
                        <thead><tr className="tableizer-firstrow"><th>NOMBRE</th><th>CARGO </th><th>CORREO </th><th>CEL. CORPORATIVO </th><th>EXT. </th></tr></thead><tbody>
                          <tr><td>ANDREA MILENA GARCÍA VÁSQUEZ </td><td>Asesora Jurídica - Notaria Encargada </td><td>agarcia@notaria21cali.com</td><td>3174037890 - 3152336135 </td><td>115</td></tr>
                          <tr><td>BAYRON JAVIER RINCÓN OSPINA </td><td>Auxiliar de Contabilidad</td><td>auxcontable2@notaria21cali.com</td><td>3166530600 - 3137518399 </td><td>108</td></tr>
                          <tr><td>BRAYAN ESTEBAN RIVERA POLANCO </td><td>Auxiliar de Oficina</td><td>cierre@notaria21cali.com</td><td>3152336135</td><td>114</td></tr>
                          <tr><td>CARLOS ENRIQUE GUTIÉRREZ</td><td>Control de Legalidad </td><td>cgutierrez@noraria21cali.com</td><td>3152336135</td><td>113</td></tr>
                          <tr><td>CLAUDIA LORENA CARDONA ECHEVERRY</td><td>Directora Jurídica y Administrativa</td><td>clcardona@notaria21cali.com</td><td>3174037890</td><td>111</td></tr>
                          <tr><td>CRISTHIAN CAMILO BALANTA RIASCOS </td><td>Auxiliar de Contabilidad</td><td>auxiliarcontable@notaria21cali.com  auxcontable1@notaria21cali.com</td><td>3166530600 - 3137518399  </td><td>106</td></tr>
                          <tr><td>DAVID ANDRÉS CUENCA TRUJILLO </td><td>Auxiliar de Matrimonios</td><td>matrimonios@notaria21cali.com</td><td>3152336135</td><td>118</td></tr>
                          <tr><td>DIEGO LOZANO ARBELÁEZ </td><td>Auxiliar de Oficina</td><td>registro@notaria21cali.com</td><td>3185009110</td><td>114</td></tr>
                          <tr><td>DIEGO PEREA RAMÍREZ</td><td>Auxiliar de Oficina</td><td>registro@notaria21cali.com</td><td>3185009110</td><td>110</td></tr>
                          <tr><td>DOLLY DEL CARMEN CARDONA MANQUILLO</td><td>Boleta Fiscal y Registro</td><td>boleta@notaria21cali.com - escrituracion7@notaria21cali.com</td><td>3152336135</td><td>114</td></tr>
                          <tr><td>EDWIN JESUS BETANCURT </td><td>Auxiliar de Autenticaciones</td><td>ebetancourt@notaria21cali.com</td><td>&nbsp;</td><td>120</td></tr>
                          <tr><td>EDWIN VICENTE DAZA DORADO </td><td>Auxiliar de Oficina</td><td>veintiuncali@supernotariado.gov.co </td><td>&nbsp;</td><td>124</td></tr>
                          <tr><td>STELLA ECHEVERRY </td><td>Domicilios</td><td>&nbsp;</td><td>3185009110</td><td>124</td></tr>
                          <tr><td>GINA MARCELA DOMÍNGUEZ </td><td>Auxiliar de Escrituración - Constructoras</td><td>gdominguez@notaria21cali.com escrituracion17@notaria21cali.com</td><td>3174037890</td><td>123</td></tr>
                          <tr><td>GLORIA DEISY MOSQUERA HERNÁNDEZ </td><td>Gerente Administrativo</td><td>rrhh@notaria21cali.com</td><td>3174037890</td><td>104</td></tr>
                          <tr><td>HOLMES RAFAEL CARDONA MONTOYA </td><td>Notario Titular</td><td>racardona@notaria21cali.com</td><td>&nbsp;</td><td>102</td></tr>
                          <tr><td>JENNIFER TORRES BARONA </td><td>Auxiliar de Escrituración</td><td>jtorres@notaria21cali.com</td><td>3158996822</td><td>103</td></tr>
                          <tr><td>JONATHAN PALACIOS GARCÍA  </td><td>Control de Legalidad</td><td>controldelegalidad2@notaria21cali.com</td><td>&nbsp;</td><td>122</td></tr>
                          <tr><td>JOSE DANIEL ROJAS SAA </td><td>Auxiliar de Autenticaciones</td><td>&nbsp;</td><td>&nbsp;</td><td>120</td></tr>
                          <tr><td>JUAN SEBASTIÁN FERNÁNDEZ CAMACHO </td><td>Control de Legalidad y Calidad</td><td>soporte@notaria21cali.com</td><td>3166530600</td><td>122</td></tr>
                          <tr><td>JULIÁN ANDRÉS LÓPEZ LENIS </td><td>Auxiliar de Escrituración</td><td>julianlnotaria21@gmail.com</td><td>3158996822</td><td>105</td></tr>
                          <tr><td>JULIÁN RICARDO CÓRDOBA RIASCOS </td><td>Auxiliar de protocolo</td><td>copias@notaria21cali.com</td><td>3152336135</td><td>114</td></tr>
                          <tr><td>KAREN STEPHANY DAZA DORADO </td><td>Auxiliar de Reparto - Constructoras</td><td>kdazanotaria21cali@gmail.com</td><td>3152336135</td><td>121</td></tr>
                          <tr><td>KEVIN DANIEL LOAIZA VEGA </td><td>Auxiliar de Oficina</td><td>&nbsp;</td><td>3185009110</td><td>124</td></tr>
                          <tr><td>LAURA LIZETH TORRES MOSQUERA </td><td>Auxiliar de Escrituración</td><td>auxiliaroficina@notaria21cali.com</td><td>3174037890</td><td>112</td></tr>
                          <tr><td>LEONEL ANDRÉS GUTIÉRREZ RUBIANO </td><td>Auxiliar de Matrimonios</td><td>matrimonios@notaria21cali.com</td><td>3152336135</td><td>121</td></tr>
                          <tr><td>LILIANA SARASTY  </td><td>Auxiliar de Escrituración</td><td>lsarasty@notaria21cali.com</td><td>3158996822</td><td>105</td></tr>
                          <tr><td>LUZ STELLA GARCÍA </td><td>Registro Civil</td><td>registro@notaria21cali.com</td><td>3185009110</td><td>110</td></tr>
                          <tr><td>MARÍA ISABEL BORRERO MIRA </td><td>Auxiliar de Escrituración</td><td>escrituracion10@notaria21cali.com</td><td>3158996822</td><td>103</td></tr>
                          <tr><td>MARIO FABIÁN LIBREROS</td><td>Auxiliar de Reparto</td><td>reparto2@notaria21cali.com</td><td>3185009110</td><td>107</td></tr>
                          <tr><td>MARTHA INÉS FLÓREZ </td><td>Cajera</td><td>&nbsp;</td><td>&nbsp;</td><td>120</td></tr>
                          <tr><td>MARYURY BETANCOURT MOLANO </td><td>Asistente Administrativo</td><td>asistenteadministrativo@notaria21cali.com  info@notaria21cali.com</td><td>3185009110</td><td>101</td></tr>
                          <tr><td>OMAR DAVID CARDONA RIASCOS </td><td>Auxiliar de Escrituración</td><td>escrituracion4@notaria21cali.com</td><td>3174037890</td><td>112</td></tr>
                          <tr><td>OMAR DARÍO IBARRA BUCHELI </td><td>Auxiliar de Escrituración - Constructoras</td><td>reparto1@notaria21cali.com</td><td>3174037890</td><td>123</td></tr>
                          <tr><td>PASTORA ECHEVERRY DE CARDONA </td><td>Gerente  General</td><td>paecheverry@notaria21cali.com</td><td>&nbsp;</td><td>116</td></tr>
                          <tr><td>SANDRA PATRICIA VIVAS </td><td>Auxiliar de Escrituración</td><td>svivas@notaria21cali.com</td><td>3174037890</td><td>112</td></tr>
                          <tr><td>SONIA NOELBA  ARBOLEDA </td><td>Conserje y aseadora</td><td>&nbsp;</td><td>&nbsp;</td><td>101</td></tr>
                          <tr><td>SORAIDA VIVEROS MONDRAGÓN </td><td>Aseadora</td><td>&nbsp;</td><td>&nbsp;</td><td>101</td></tr>
                          <tr><td>YENIFER ARBOLEDA HERNÁNDEZ </td><td>Auxiliar de Escrituración</td><td>yarboleda@notaria21cali.com</td><td>3152336135</td><td>117</td></tr>
                          <tr><td>WILDER ALBERTO FLÓREZ NOSSA </td><td>Mensajero</td><td>&nbsp;</td><td>3185009105</td><td>101</td></tr>
                        </tbody>     
                      </table>
                    </CardBody>
                  </Card>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Notario;