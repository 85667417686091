import React from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components

import {useDispatch} from 'react-redux'
import Axios from 'axios'
import {cargarNotaria} from '../actions/notariaAction'


// core components
import PresentationHeader from "components/Headers/PresentationHeader.js";

// sections for this page
import Servicios from "./presentation-sections/Servicios.js";
import Linea from "./presentation-sections/Linea.js";
import Oficina from "./presentation-sections/Oficina.js";
import Contacto from "./presentation-sections/Contacto.js";

function Presentation() {

  const dispatch = useDispatch()

  React.useEffect(() => {
    
    const consultar = async () =>{
      let config = {
        method: 'GET',
        mode: 'cors',
        headers: {"Access-Control-Allow-Origin": true,Authorization: "Bearer "+ process.env.REACT_APP_TOKEN_API},
        
      }

      const resultado = await Axios.get(process.env.REACT_APP_UrlApi+'notaria',config)

      if(resultado.status === 200){
        let dataTemp ={}        
        resultado.data.forEach((element)=>{
          dataTemp[element.variable]=element.value
        })
        dispatch(cargarNotaria(dataTemp))
      }
   }
   consultar()
  },[]);

  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
     // initialise Rellax for this page
    if (window.innerWidth >= 991) {
      setTimeout(function () {
        new Rellax(".rellax", {
          center: true,
        });
      }, 5000);
      new Rellax(".rellax-header");
      new Rellax(".rellax-text");
       //window.scrollTo(0, 0);
          document.body.scrollTop = 0;
    }

    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#/") + 2
    );
    var hrefId = href.substring(href.lastIndexOf("#") + 1);
    if (href.lastIndexOf("#") > 0) {
      document.getElementById(hrefId).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }

    // we need to add a script for the github buttons
    let script = document.createElement("script");
    script.src = "https://buttons.github.io/buttons.js";
    script.id = "github-buttons-script-id";
    document.body.appendChild(script);

    return function cleanup() {
      document.body.classList.remove("presentation-page");
      document.body.classList.remove("sidebar-collapse");

      // we need to remove the script when we change the page
      script.parentNode.removeChild(script);
    };
  });
  return (
    <>
      <div className="wrapper">
        <PresentationHeader />
        <Servicios />
        <Linea />
        <Oficina />
        <Contacto />
      </div>
    </>
  );
}

export default Presentation;
