import React from "react";

// reactstrap components
import {  Modal,
          ModalBody,
          ModalFooter,
          Button,
          Row,
          Col,
          Card,
          CardBody
} from "reactstrap";

// core components

function Tramites(props) {

  return (
    <>
      <Modal isOpen={props.modal} toggle={props.setModal} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{backgroundColor: "White" ,borderRadius: "15px"}}>
            <div className="modal-header">
              <h3> </h3>
              <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={props.setModal}
              >
              <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
              </button>
            </div>
            <ModalBody>
                <h3 className="title text-center" style={{color:"#2c2c2c"}} id="exampleModalLiveLabel">
                  Los trámites que adelantan las Notarías son los siguientes:
                </h3>
              <Row className="justify-content-center">
                <Col md="12">
                  <Card>
                    <CardBody>
                      <h4>
                        <ol>
                          <li>Recibir, extender y autorizar las declaraciones que conforme a las Leyes requieran escritura 
                            pública y aquellas a las cuales los interesados quieran revestir de esta solemnidad.
                          </li>
                          <li>Autorizar el reconocimiento espontáneo de documentos privados. </li>
                          <li>Dar testimonio de la autenticidad de firmas de funcionarios o particulares y de otros Notarios que 
                            las tengan registradas ante ellos.
                          </li>
                          <li>Dar fe de la correspondencia o identidad que exista entre un documento que tenga a la vista y su 
                            copia mecánica o literal.
                          </li>
                          <li>Acreditar la existencia de las personas naturales y expedir la correspondiente fe de vida.</li>
                          <li>Recibir y guardar dentro del protocolo los documentos o actuaciones que la Ley o el Juez ordenen 
                            protocolizar o que los interesados quieran proteger de esta manera.
                          </li>
                          <li>Expedir copias o certificaciones según el caso, de los documentos que reposen en sus archivos.</li>
                          <li>Dar testimonio escrito con fines jurídico - probatorios de los hechos percibidos por ellos dentro del ejercicio de 
                            sus Tramites y de que no haya quedado dato formal en sus archivos.
                          </li>
                          <li>Intervenir en el otorgamiento, extensión y autorización de los testamentos solemnes que conforme a la 
                            Ley civil deban otorgarse ante ellos.
                          </li>
                          <li>Practicar apertura y publicación de los testamentos cerrados.</li>
                          <li>Llevar el registro del estado civil de las personas en los casos, por los sistemas y con las formalidades prescritos en la Ley.</li>
                          <li>Las demás Tramites que les señalen las Leyes. </li>                  
                        </ol>
                      </h4>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
              <Col md="4">
                <Card>
                  <CardBody className="text-center">
                    <h4 className="text-center" style={{marginBottom: "45px"}}>Normatividad</h4>
                    <a href="/normativa"
                      role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                        <b style={{fontSize: "16px"}}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card>
                  <CardBody className="text-center">
                    <h4 className="text-center">Protocolos de atención</h4>
                    <a href="https://www.ucnc.com.co/sitio/wp-content/uploads/2020/09/2020-08-21-PROTOCOLO-DE-BIOSEGURIDAD-PARA-NOTARIAS-UCNC-Version-Final-Anexo-SNR.pdf" 
                      style={{marginBottom: "10px"}}
                        role="button" className="btn btn-azul"  target="_black" aria-disabled="true">
                        <b style={{fontSize: "16px"}}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card>
                  <CardBody className="text-center">
                    <h4 className="text-center">Formato y formularios</h4>
                    <a href="/formato" 
                      role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                        <b style={{fontSize: "16px"}}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
            </Row> 
            </ModalBody>
            <ModalFooter>
              <Button
                  color="default"
                  type="button"
                  onClick={props.setModal}
                  className="text-center"
              >
                  <b style={{fontSize: "17px"}}>Cerrar</b>
              </Button>
            </ModalFooter>
        </div>
    </Modal>
    </>
  );
}

export default Tramites;
