import React from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";
// core components

import PresentationHeader from "components/Headers/PresentationHeader2.js";
// sections for this page
// import Politica from "./Politicas/Politicas.js";


function RegistroCivil() {
  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // initialise Rellax for this page
    if (window.innerWidth >= 991) {
      setTimeout(function () {
        new Rellax(".rellax", {
          center: true,
        });
      }, 5000);
      new Rellax(".rellax-header");
      new Rellax(".rellax-text");
    }

    // we need to add a script for the github buttons
    let script = document.createElement("script");
    script.src = "https://buttons.github.io/buttons.js";
    script.id = "github-buttons-script-id";
    document.body.appendChild(script);

    return function cleanup() {
      document.body.classList.remove("tramites-page");
      document.body.classList.remove("sidebar-collapse");

      // we need to remove the script when we change the page
      script.parentNode.removeChild(script);
    };
  });
  return (
    <>
      <div className="wrapper">
        <PresentationHeader titulo="Registro Civil" />
        <div id="servicio" style={{ backgroundColor: "#f9f9f9" }}>
          <Container className="fluid">
            <Row className="justify-content-center">
              <Col md="8" style={{ marginTop: "2%" }}>
                <center>
                  <img
                    alt="Imagen con referencia registraduria"
                    width="160px"
                    src={require("assets/img/Notaria/registraduria.png")}
                  ></img>
                  <br></br>
                  <h3 className="texto">Señor(a) usuario(a) si se encuentra en otra ciudad, puede pedir copia de su registro civil de nacimiento, matrimonio o defunción a info@notaria21cali.com. En la parte inferior podra ir a la zona de pagos para realizar el pago correspondiente.</h3>
                  <a className="enlace"  href="https://wwww.zonapagos.com/t_notaria21cali/pagos.asp" type="button" style={{borderBottom: "none"}} target="_black">
                    <img
                      alt="Imagen con referencia Pagina de Pagos"
                      width="80px"
                      src={require("assets/img/Notaria/pse.png")}
                    ></img>
                    <br></br>
                    Ir a la Pagina de Pagos
                  </a>
                </center>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default RegistroCivil;
