import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function Oficina() {
  return (
    <>
      <div id="oficina" style={{backgroundColor: "#f9f9f9"}}>
        <div className="about-office">
          <Container>
            <Row className="text-center">
              <Col className="ml-auto mr-auto" md="8">
                <h1 className="title">Nuestras <b style={{color : "#0a006c"}}>Oficinas</b><br></br></h1>
                <h4 className="description">
                  <b>
                     Tenemos un ambiente adecuado para que usted realice sus trámites.
                  </b>
                </h4>
              </Col>
            </Row>
            <Row>
              <Col md="4" style={{marginBottom : "20px"}}>
                <img
                  alt="Fachada de la notaria 21 de Cali"
                  className="rounded img-raised"
                  src={require("assets/img/Notaria/ofi1.jpg")}
                ></img>
              </Col>
              <Col md="4" style={{marginBottom : "20px"}}>
                <img
                  alt="Entrada de la notaria 21 de Cali"
                  className="rounded img-raised"
                  src={require("assets/img/Notaria/ofi4.JPG")}
                ></img>
              </Col>
              <Col md="4" style={{marginBottom : "20px"}}>
                <img
                  alt="Entrada de la notaria 21 de Cali"
                  className="rounded img-raised"
                  src={require("assets/img/Notaria/ofi5.JPG")}
                ></img>
              </Col>
              <Col md="6" style={{marginBottom : "20px"}}>
                <img
                  alt="Reunión del personal en las oficinas de la notaria 21 de Cali"
                  className="rounded img-raised"
                  src={require("assets/img/Notaria/ofi3.jpg")}
                ></img>
              </Col>
              <Col md="6" style={{marginBottom : "20px"}}>
                <img
                  alt="oficina para la realización de matrimonios en la notaria 21 de Cali"
                  className="rounded img-raised"
                  src={require("assets/img/Notaria/ofi2.jpg")}
                ></img>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Oficina;
