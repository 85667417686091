import React from "react";

// reactstrap components
import {Card,
        CardBody,
        CardTitle,
        NavItem,
        NavLink,
        Nav,
        TabContent,
        TabPane,
        Container,
        Row,
        Col} 
  from "reactstrap";

// core components

function Servicios() {
  
  const [pills, setPills] = React.useState("1");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  return (
    <>
      <div id="servicios" style={{backgroundColor: "#f9f9f9"}} >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <h1 className="text-center title">
                  Nuestros <b style={{color : "#0a006c"}}>Servicios</b><br></br>
              </h1>
              <h4 className="text-center description">
               La <b>Notaría 21 de Cali</b> le da la bienvenida a su portal web y le ofrece los siguientes servicios.
              </h4>
              <div className="space-20"></div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="12">
              <div className="nav-align-center">
                <Nav
                  className="nav-pills-rojo nav-pills-icons"
                  pills
                  role="tablist"
                >
                  <NavItem>
                    <NavLink
                      onClick={(e) => {
                        e.preventDefault();
                        setPills("1");
                      }}
                      className={pills === "1" ? "active" : ""}
                      role="tablist"
                      href="#pablo"
                      style={{border: "1px solid #0a006c", borderRadius: "15px"}}
                    >
                      <i className="now-ui-icons files_paper"></i>
                      Escrituración
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      onClick={(e) => {
                        e.preventDefault();
                        setPills("2");
                      }}
                      className={pills === "2" ? "active" : ""}
                      role="tablist"
                      href="#pablo"
                      style={{border: "1px solid #0a006c", borderRadius: "15px"}}
                    >
                      <i className="now-ui-icons education_paper"></i>
                      Registro civil
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      onClick={(e) => {
                        e.preventDefault();
                        setPills("3");
                      }}
                      className={pills === "3" ? "active" : ""}
                      role="tablist"
                      href="#pablo"
                      style={{border: "1px solid #0a006c", borderRadius: "15px"}}
                    >
                      <i className="now-ui-icons files_single-copy-04"></i>
                      Autenticaciones
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
              <TabContent className="gallery" activeTab={"pills" + pills}>
                <TabPane tabId="pills1">
                  <Row>
                    <Col className="ml-auto mr-auto" md="5">
                      <Card
                        className="card-background-product card-raised"
                        style={{
                          borderRadius: "15px",
                          backgroundSize: "100% 100%",
                          backgroundImage:
                            "url(" + require("assets/img/Notaria/escrituracion.jpg") + ")",
                        }}
                      >
                        <CardBody className="text-center">
                          <CardTitle tag="h2" style={{color: "white"}}><b>Escrituración</b></CardTitle>
                          <h4 className="card-description text-white">
                            <b>
                            Documento que contiene la declaración de voluntad de una o varias personas, 
                            emitidas ante el notario, para hacer un determinado contrato o un acto 
                            jurídico individual.
                            </b>
                          </h4>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="5">
                      <div className="info info-horizontal">
                        <div className="icon icon-rojo">
                          <i className="now-ui-icons users_single-02"></i>
                        </div>
                        <div className="description">
                          <h4 className="info-title">Requisitos</h4>
                          <h5 className="description">
                            <ol>
                              <b><li>Asistir personalmente a la notaría.</li></b>
                              <b><li>Llevar cédula de ciudadanía.</li></b>
                              <b><li>Llevar documento privado que se va a firmar.</li></b>
                              <b><li> Llevar testigo cuando así se requiera.</li></b>
                            </ol>
                          </h5>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="pills2">
                  <Row>
                    <Col className="ml-auto mr-auto" md="5">
                      <Card
                        className="card-background-product card-raised"
                        style={{
                          borderRadius: "15px",
                          backgroundSize: "100% 100%",
                          backgroundImage:
                            "url(" + require("assets/img/Notaria/registro.jpg") + ")",
                        }}
                      >
                        <CardBody className="text-center">
                          <CardTitle tag="h2" style={{color :"White"}}><b>Registro Civil</b></CardTitle>
                          <h4 className="card-description text-white">
                            <b>
                            Es un documento indispensable mediante el cual la persona 
                            prueba ante la familia y la sociedad su existencia y le
                             permite identificarse, ejercer sus derechos y cumplir 
                             sus obligaciones.
                            </b>
                          </h4>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="5">
                      <div className="info info-horizontal">
                        <div className="icon icon-rojo">
                          <i className="now-ui-icons users_single-02"></i>
                        </div>
                        <div className="description">
                          <h4 className="info-title">Requisitos</h4>
                          <h5 className="description">
                            <ol>
                              <b><li>Certificado de nacido vivo.</li></b>
                              <b><li>Documento de identificación de los padres.</li></b>
                            </ol>
                            <i><b>La inscripción del registro civil no tiene costo.</b></i>
                          </h5>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="pills3">
                  <Row>
                    <Col className="ml-auto mr-auto" md="5">
                      <Card
                        className="card-background-product card-raised"
                        style={{
                          borderRadius: "15px",
                          backgroundSize: "100% 100%",
                          backgroundImage:
                            "url(" + require("assets/img/Notaria/autenticaicones.jpg") + ")",
                        }}
                      >
                        <CardBody className="text-center">
                          <CardTitle tag="h2" style={{color: "White"}}><b>Autenticaciones</b></CardTitle>
                            <h4 className="card-description text-white">
                              <b>
                              Es cuando el notario da testimonio escrito de las firma 
                              que aparecen en un documento privado fueron puestas en 
                              su presencia, estableciendo la identidad de los firmantes.
                              </b>
                            </h4>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="5">
                      <div className="info info-horizontal">
                        <div className="icon icon-rojo">
                          <i className="now-ui-icons users_single-02"></i>
                        </div>
                        <div className="description">
                          <h4 className="info-title">Requisitos</h4>
                          <h5 className="description">
                            <ol>
                              <b><li>Asistir personalmente a la notaría. Puede hacerse también a domicilio</li></b>
                              <b><li>Llevar cédula de ciudadanía.</li></b>
                              <b><li>Llevar documento que se va a autenticar.</li></b>
                            </ol>
                          </h5>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Servicios;
