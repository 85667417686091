import React from "react";

// reactstrap components
import {  Modal,
          ModalBody,
          ModalFooter,
          Button,
} from "reactstrap";

// core components

function Mision(props) {

  return (
    <>
      <Modal isOpen={props.modal} toggle={props.setModal} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{backgroundColor: "White" ,borderRadius: "15px"}}>
            <div className="modal-header">
                <h3> </h3>
                <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={props.setModal}
                >
                <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
                </button>
            </div>
            <ModalBody>
              <h3 className="text-center title">
                    <b style={{color : "#1c6e38"}}>Quienes Somos</b><br></br>
                </h3>
                <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>La Notaría 21 del Círculo de Santiago de Cali, fue creada por Decreto No. 968 de 1997; por decreto se designa a Holmes Rafael Cardona Montoya, como notario, quien toma posesión como interino el 15 de agosto de 1.997 y el 14 de Abril de 1997 toma posesión como Notario en Propiedad después de haber superado el concurso de méritos que realizará el Gobierno Nacional.
                      <br/>Estamos ubicados en el Centro comercial Holguines Trade Center, Carrera 100 # 11-60 Locales 234A, 234B, 234C, 235, 235A, 235B, desde su nacimiento, contamos con un grupo interdisciplinario para la prestación del servicio notarial. 
                    </b>
                </h5>
                <h3 className="text-center title">
                    <b style={{color : "#1c6e38"}}>Misión</b><br></br>
                </h3>
                <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Ofrecemos los servicios notariales con sentido social, de manera efectiva, personalizada, 
                      contando con tecnología adecuada y personal altamente capacitado; buscando permanentemente 
                      la excelencia.  Destacándonos por brindar asesoría rápida, confiable, dando solución real a 
                      la demanda de los usuarios, dentro del marco legal. </b>
                </h5>
                <h3 className="text-center title">
                    <b style={{color : "#1c6e38"}}>Visión</b><br></br>
                </h3>
                <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Ser en los próximos cinco años una notaria líder en el suroccidente colombiano,  
                      en la prestación del servicio, cumpliendo los requisitos legales, utilizando 
                      tecnología avanzada y ejecutando continuamente políticas de capacitación 
                      al personal para atraer más usuarios a partir de la confianza, lealtad y 
                      total satisfacción de los mismos.</b>
                </h5>
                <h3 className="text-center title">
                    <b style={{color : "#1c6e38"}}>Valores Corporativos</b><br></br>
                </h3>
                <h5 className="description text-justify" style={{color: "Black"}}>
                <b>Respeto:</b> ofrecemos buen trato al usuario, a los compañeros de trabajo, a las normas que rigen la función notarial.
                <br/><b>Trabajo en equipo:</b> brindamos el apoyo necesario para lograr objetivos comunes; compartimos el conocimiento con nuestros trabajadores e integramos las habilidades del personal para dar cumplimiento a los requisitos del cliente. 
                <br/><b>Honestidad:</b> No trasgredimos las normas legales, ni los procedimientos internos por incentivos económicos externos.
                <br/><b>Responsabilidad:</b> Asumimos los deberes y obligaciones adquiridas  de manera fiel, rápida, correcta y honesta.
                <br/><b>Compromiso:</b> poseemos un gran sentido de pertenencia, lo que nos impulsa a contribuir para que la organización alcance sus metas y objetivos.
                <br/><b>Lealtad:</b> Cumplimos los compromisos adquiridos, acatamos las leyes y las normas internas, con amor al trabajo, puntualidad, honradez, responsabilidad y solidaridad.
                </h5>
            </ModalBody>
            <ModalFooter>
              <Button
                  color="default"
                  type="button"
                  onClick={props.setModal}
                  className="text-center"
              >
                  <b style={{fontSize: "17px"}}>Cerrar</b>
              </Button>
            </ModalFooter>
        </div>
    </Modal>
    </>
  );
}

export default Mision;
