import React from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components
import {
    Container,
    Row,
    Col,
    Card,
    CardBody
} from "reactstrap";

import PresentationHeader from "components/Headers/PresentationHeader2.js";


function Diagnostico() {

    React.useEffect(() => {
        document.body.classList.add("presentation-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;

        // initialise Rellax for this page
        if (window.innerWidth >= 991) {
            setTimeout(function () {
                new Rellax(".rellax", {
                    center: true,
                });
            }, 5000);
            new Rellax(".rellax-header");
            new Rellax(".rellax-text");
        }

        // we need to add a script for the github buttons
        let script = document.createElement("script");
        script.src = "https://buttons.github.io/buttons.js";
        script.id = "github-buttons-script-id";
        document.body.appendChild(script);

        return function cleanup() {
            document.body.classList.remove("tramites-page");
            document.body.classList.remove("sidebar-collapse");

            // we need to remove the script when we change the page
            script.parentNode.removeChild(script);
        };
    });
    return (
        <>
            <Container className="fluid">
                <PresentationHeader titulo="Temas Interes" />
                <Row className="justify-content-center">
                    <Col md="12">
                        <Card md="12">
                            <CardBody>
                                <p className="text-justify" style={{ fontSize: "14px", color:"#68696F", fontFamily:"serif" }}>
                                    Publicar los temas de interés sobre los cuales se adelantará el diagnóstico de necesidades e identificación de problemas con la participación de los ciudadanos y grupos de valor.
                                </p>
                                <center>
                                    <a type="button" href={"https://drive.google.com/file/d/1HOIqhmiILYZmXBHh4uWJYSOitrCG09z4/view?usp=sharing"} style={{ borderBottom: "none" }} target="_black">
                                        <img
                                            alt="Imagen con referencia hacia un icono para descargar un archivo en PDF"
                                            width="80px"
                                            src={require("assets/img/Notaria/PDF.png")}
                                        ></img>
                                        <h6 className="texto">Temas Interes</h6>
                                    </a>
                                </center>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Diagnostico;