import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {

  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container
} from "reactstrap";

import Funciones from "../../views/Molda/Funciones.js";
import Organigrama from "../../views/Molda/Organigrama.js";
import Tramites from "../../views/Molda/Tramites.js";
import Mision from "../../views/Molda/Mision.js";

function DropdownWhiteNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const[Modal1, setModal1] = React.useState(false);
  const[Modal2, setModal2] = React.useState(false);
  const[Modal3, setModal3] = React.useState(false);
  const[Modal4, setModal4] = React.useState(false);

  React.useEffect(()=>{
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let modal

    if(urlParams.has('modal')){
        modal = urlParams.get('modal')
        switch(modal){
          case '3.4':setModal1(true); break;
          case '3.1':setModal2(true); break;
          case '9.1':setModal3(true); break;
          case '3.2':setModal4(true); break;     
          default: break;     
        }
    }    
  },[])
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className="bg-white fixed-top" expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand to="/" tag={Link} id="navbar-brand">
              <img src={require("assets/img/Notaria/logoNot.png")} alt="Logo de la notaria 21 de Cali" width="110px"></img>
            </NavbarBrand>
            <button
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              className="navbar-toggler"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse isOpen={collapseOpen} navbar>
            <Nav className="ml-auto" id="ceva" navbar>
              <NavItem>
                <h5>
                  <NavLink to="/" tag={Link}>
                    <p style={{fontSize: "11px"}}><b>INICIO</b></p>
                   </NavLink>
                </h5>
              </NavItem>
              <UncontrolledDropdown nav>
                <h5>
                  <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    id="navbarDropdownMenuLink1"
                    nav
                    onClick={(e) => e.preventDefault()}
                  >
                    <p style={{fontSize: "11px"}}><b>SERVICIOS NOTARIALES</b></p>
                  </DropdownToggle>
                </h5>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink1" end>
                  {/* <DropdownItem href="http://notariavirtual.notaria21cali.com.co/" >
                    <i className="now-ui-icons tech_laptop"></i>
                    Notaría Virtual
                  </DropdownItem> */}
                  <DropdownItem to="/cotizador" tag={Link}>
                    <i className="now-ui-icons tech_laptop"></i>
                    Cotizador
                  </DropdownItem>
                  
                  <DropdownItem to="/clientescorporativos" tag={Link}>
                    <i className="now-ui-icons business_briefcase-24"></i>
                    Clientes corporativos
                  </DropdownItem>
                  <DropdownItem to="/registroCivil" tag={Link}>
                    <i className="now-ui-icons business_briefcase-24"></i>
                    Registro Civil
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <h5>
                  <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    id="navbarDropdownMenuLink"
                    nav
                    onClick={(e) => e.preventDefault()}
                  >
                    <p style={{fontSize: "11px"}}><b>CONÓCENOS</b></p>
                  </DropdownToggle>
                </h5>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink" end>
                  <DropdownItem onClick={() => setModal2(true)}>
                    <i className="now-ui-icons files_single-copy-04"></i>
                    Misión y Visión 
                  </DropdownItem>
                  <DropdownItem  onClick={() => setModal4(true)}>
                    <i className="now-ui-icons users_circle-08"></i>
                    Funciones y deberes
                  </DropdownItem>
                  <DropdownItem to="/procesos" tag={Link}>
                    <i className="now-ui-icons ui-2_settings-90"></i>
                    Procesos y procedemiento
                  </DropdownItem>
                  <DropdownItem to="/Organigrama" tag={Link}>
                    <i className="now-ui-icons design_image"></i>
                    Organigrama
                  </DropdownItem>
                  <DropdownItem to="/#linea" tag={Link} >
                    <i className="now-ui-icons education_paper"></i>
                    Noticias
                  </DropdownItem>
                  <DropdownItem to="/notario" tag={Link}>
                    <i className="now-ui-icons business_badge"></i>
                    Notario
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <h5>
                  <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    id="navbarDropdownMenuLink1"
                    nav
                    onClick={(e) => e.preventDefault()}
                  >
                    <p style={{fontSize: "11px"}}><b>ATENCION Y SERVICIOS A LA CIUDADANIA</b></p>
                  </DropdownToggle>
                </h5>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink1" end>
                  <DropdownItem onClick={() => setModal3(true)}>
                    <i className="now-ui-icons files_paper"></i>
                    Información
                  </DropdownItem>
                  <DropdownItem to="/servicios" tag={Link}>
                    <i className="now-ui-icons education_agenda-bookmark"></i>
                    Servicios
                  </DropdownItem>
                  <DropdownItem to="/tarifa" tag={Link}>
                    <i className="now-ui-icons business_money-coins"></i>
                    Tarifas
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <h5>
                  <NavLink to="/#contacto" tag={Link}>
                    <p style={{fontSize: "11px"}}><b>CONTÁCTENOS</b></p>
                   </NavLink>
                </h5>
              </NavItem>
              <NavItem>
                <h5>
                  <NavLink to="/transparencia/0" tag={Link}>
                    <p style={{fontSize: "11px"}}><b>TRANSPARENCIA</b></p>
                   </NavLink>
                </h5>
              </NavItem>
              <NavItem>
                <h5>
                  <NavLink to="/participa" tag={Link}>
                    <p style={{fontSize: "11px"}}><b>PARTICIPA</b></p>
                   </NavLink>
                </h5>
              </NavItem>
              <NavItem>
                <h5>
                  <NavLink to="/zonaPagos" tag={Link}>
                    <p style={{fontSize: "11px"}}><b>ZONA PAGOS</b></p>
                   </NavLink>
                </h5>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      <Organigrama modal={Modal1} setModal={() => setModal1(false)}/>
      <Mision modal={Modal2} setModal={() => setModal2(false)}/>
      <Tramites modal={Modal3} setModal={() => setModal3(false)}/>
      <Funciones modal={Modal4} setModal={() => setModal4(false)}/>
      {/* <Modal isOpen={Modal1} toggle={() => setModal1(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{backgroundColor: "White" ,borderRadius: "15px", border: "3px solid #979797"}}>
            <div className="modal-header">
                <h3> </h3>
                <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={() => setModal1(false)}
                >
                <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
                </button>
            </div>
            <ModalBody>
                <h1 className="text-center title">
                    <b style={{color : "#1c6e38"}}>Organigrama</b><br></br>
                </h1>
                <Row>
                  <Col md="12">
                  <img
                      alt="..."
                      width="100%"
                      src={require("assets/img/Notaria/organigrama.JPG")}
                  ></img>
                  </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="default"
                    type="button"
                    onClick={() => setModal1(false)}
                    className="text-center"
                >
                    <b style={{fontSize: "17px"}}>Cerrar</b>
                </Button>
            </ModalFooter>
        </div>
    </Modal> */}
    {/* <Modal isOpen={Modal2} toggle={() => setModal2(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{backgroundColor: "White" ,borderRadius: "15px", border: "3px solid #979797"}}>
            <div className="modal-header">
                <h3> </h3>
                <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={() => setModal2(false)}
                >
                <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
                </button>
            </div>
            <ModalBody>
                <h3 className="text-center title">
                    <b style={{color : "#1c6e38"}}>Misión</b><br></br>
                </h3>
                <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Ofrecemos los servicios notariales con sentido social, de manera efectiva, personalizada, 
                      contando con tecnología adecuada y personal altamente capacitado; buscando permanentemente 
                      la excelencia.  Destacándonos por brindar asesoría rápida, confiable, dando solución real a 
                      la demanda de los usuarios, dentro del marco legal. </b>
                </h5>
                <h3 className="text-center title">
                    <b style={{color : "#1c6e38"}}>Visión</b><br></br>
                </h3>
                <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Ser en los próximos cinco años una notaria líder en el suroccidente colombiano,  
                      en la prestación del servicio, cumpliendo los requisitos legales, utilizando 
                      tecnología avanzada y ejecutando continuamente políticas de capacitación 
                      al personal para atraer más usuarios a partir de la confianza, lealtad y 
                      total satisfacción de los mismos.</b>
                </h5>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="default"
                    type="button"
                    onClick={() => setModal2(false)}
                    className="text-center"
                >
                    <b style={{fontSize: "17px"}}>Cerrar</b>
                </Button>
            </ModalFooter>
        </div>
    </Modal> */}
    </>
  );
}

export default DropdownWhiteNavbar;
