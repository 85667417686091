import React from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components
import {
    Container,
    Row,
    Col,
    Card,
    CardBody
} from "reactstrap";

import PresentationHeader from "components/Headers/PresentationHeader2.js";

function Diagnostico() {

    React.useEffect(() => {
        document.body.classList.add("presentation-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;

        // initialise Rellax for this page
        if (window.innerWidth >= 991) {
            setTimeout(function () {
                new Rellax(".rellax", {
                    center: true,
                });
            }, 5000);
            new Rellax(".rellax-header");
            new Rellax(".rellax-text");
        }

        // we need to add a script for the github buttons
        let script = document.createElement("script");
        script.src = "https://buttons.github.io/buttons.js";
        script.id = "github-buttons-script-id";
        document.body.appendChild(script);

        return function cleanup() {
            document.body.classList.remove("tramites-page");
            document.body.classList.remove("sidebar-collapse");

            // we need to remove the script when we change the page
            script.parentNode.removeChild(script);
        };
    });
    return (
        <>
        <div className="section section-gray" id="cards">
            <Container className="tim-container">
                <PresentationHeader titulo="Colaboracion Innovacion Abierta"/>
                <Row className="justify-content-center">
                    <Col md="12">
                        <Card>
                            <CardBody>
                                <p className="text-justify" style={{ fontSize: "14px", color:"#68696F", fontFamily:"serif" }}>
                                    <b>La colaboración e innovación abierta</b> es entendida como la interacción con la ciudadanía para la búsqueda 
                                    de soluciones a problemáticas públicas con el conocimiento de los grupos de valor y así resolver los desafíos de 
                                    las entidades, de modo que se establezcan redes de cooperación y sinergias que permitan comprender las problemáticas 
                                    o necesidades de una población particular, abrir canales para recibir ideas y propuestas de solución y lograr la 
                                    selección final de las más viables.*
                                    <br></br>
                                    <br></br>
                                    <a href="https://www.funcionpublica.gov.co/inicio?p_p_state=maximized&p_p_mode=view&saveLastPath=false&_com_liferay_login_web_portlet_LoginPortlet_mvcRenderCommandName=%2Flogin%2Flogin&p_p_id=com_liferay_login_web_portlet_LoginPortlet&p_p_lifecycle=0&_com_liferay_login_web_portlet_LoginPortlet_redirect=%2Fdocuments%2F">
                                        <b style={{ color: '#045FB4' }}>
                                        * Departamento Administrativo de la Función Pública (2021). Lineamientos para publicar información en el menú participa sobre participación ciudadana en la gestión pública. Obtenido de: https://www.funcionpublica.gov.co/documents/
                                        </b>
                                    </a>
                                </p>
                                <center>
                                    <a type="button" href={"https://drive.google.com/file/d/1LLdQGnPxkjVHkn8qTnpsv0tMyYut_Zev/view?usp=sharing"} style={{ borderBottom: "none" }} target="_black">
                                        <img
                                            alt="Imagen con referencia hacia un icono para descargar un archivo en PDF"
                                            width="80px"
                                            src={require("assets/img/Notaria/PDF.png")}
                                        ></img>
                                        <h6 className="texto">Colaboracion E Innovacion Abierta</h6>
                                    </a>
                                    <br></br>
                                    <a type="button" href={"https://drive.google.com/file/d/17krX7mU-jRGOtX_1H-_NyzjojZSYlx_F/view?usp=sharing"} style={{ borderBottom: "none" }} target="_black">
                                        <img
                                            alt="Imagen con referencia hacia un icono para descargar un archivo en PDF"
                                            width="80px"
                                            src={require("assets/img/Notaria/PDF.png")}
                                        ></img>
                                        <h6 className="texto">Mecanismo de Atencion</h6>
                                    </a>
                                    <br></br>
                                    <a type="button" href={"https://drive.google.com/file/d/1zATeiW6RQOR4S71AIO-suBqFBffFYBHq/view?usp=sharing"} style={{ borderBottom: "none" }} target="_black">
                                        <img
                                            alt="Imagen con referencia hacia un icono para descargar un archivo en PDF"
                                            width="80px"
                                            src={require("assets/img/Notaria/PDF.png")}
                                        ></img>
                                        <h6 className="texto">Pantallazo PQRS</h6>
                                    </a>
                                </center>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            </div>
        </>
    );
}

export default Diagnostico;