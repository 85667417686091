import React from "react";

// reactstrap components
import { Container, 
          Row, 
          Col,
          Card,
          CardBody,
          CardTitle,
          Modal,
          ModalBody,
          ModalFooter,
          Button,
          NavItem,
          NavLink,
          Nav,
          TabContent,
          TabPane
} from "reactstrap";

// core components

function TramitesServicios() {

    const [iconTabs, setIconTabs] = React.useState("1");
    const [iconTabs2, setIconTabs2] = React.useState("1");
    const[Modal1, setModal1] = React.useState(false);
    const[Modal2, setModal2] = React.useState(false);
    const[Modal3, setModal3] = React.useState(false);
    const[Modal4, setModal4] = React.useState(false);
    const[Modal5, setModal5] = React.useState(false);
    const[Modal6, setModal6] = React.useState(false);
    const[Modal7, setModal7] = React.useState(false);
    const[Modal8, setModal8] = React.useState(false);
    const[Modal9, setModal9] = React.useState(false);
    const[Modal10, setModal10] = React.useState(false);
    const[Modal11, setModal11] = React.useState(false);
    const[Modal12, setModal12] = React.useState(false);
    const[Modal13, setModal13] = React.useState(false);
    const[Modal14, setModal14] = React.useState(false);
    const[Modal15, setModal15] = React.useState(false);
    const[Modal16, setModal16] = React.useState(false);
    const[Modal17, setModal17] = React.useState(false);
    const[Modal18, setModal18] = React.useState(false);
    const[Modal19, setModal19] = React.useState(false);
    const[Modal20, setModal20] = React.useState(false);
    const[Modal21, setModal21] = React.useState(false);
    const[Modal22, setModal22] = React.useState(false);
    const[Modal23, setModal23] = React.useState(false);


  return (
    <>
      <div id= "servicio" style={{backgroundColor: "#f9f9f9"}}
      >
        <Container>
        <Row className="justify-content-xl-center">
          <Col className="ml-auto mr-auto text-center" lg="12" style={{ marginTop: "30px"}}>
            {/* <Card style={{width:"100%", backgroundColor:"#fff" ,borderRadius: "15px", marginTop: "30px"}} className="text-center">
                <CardHeader> */}
                    <Nav className="justify-content-center" role="tablist" tabs  color= "#0a006c">
                        <NavItem>
                        <NavLink
                            className={iconTabs === "1" ? "active" : ""}
                            href="#"
                            style={{border: "1px solid #0a006c"}}
                            onClick={e => {
                            e.preventDefault();
                            setIconTabs("1");
                            }}
                        >
                           <i className="now-ui-icons files_paper"></i>
                             Registro Civil
                        </NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink
                            className={iconTabs === "2" ? "active" : ""}
                            href="#"
                            style={{border: "1px solid #0a006c"}}
                            onClick={e => {
                            e.preventDefault();
                            setIconTabs("2");
                            }}
                        >
                            <i className="now-ui-icons ui-2_favourite-28"></i>
                            Matrimonio
                        </NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink
                            className={iconTabs === "3" ? "active" : ""}
                            href="#"
                            style={{border: "1px solid #0a006c"}}
                            onClick={e => {
                            e.preventDefault();
                            setIconTabs("3");
                            }}
                        >
                            <i className="now-ui-icons education_paper"></i>
                            Escrituración
                        </NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink
                            className={iconTabs === "4" ? "active" : ""}
                            href="#"
                            style={{border: "1px solid #0a006c"}}
                            onClick={e => {
                            e.preventDefault();
                            setIconTabs("4");
                            }}
                        >
                            <i className="now-ui-icons ui-1_check"></i>
                            Autenticaciones
                        </NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink
                            className={iconTabs === "5" ? "active" : ""}
                            href="#"
                            style={{border: "1px solid #0a006c"}}
                            onClick={e => {
                            e.preventDefault();
                            setIconTabs("5");
                            }}
                        >
                            <i className="now-ui-icons ui-2_chat-round"></i>
                            Declaraciones
                        </NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink
                            className={iconTabs === "6" ? "active" : ""}
                            href="#"
                            style={{border: "1px solid #0a006c"}}
                            onClick={e => {
                            e.preventDefault();
                            setIconTabs("6");
                            }}
                        >
                            <i className="now-ui-icons business_briefcase-24"></i>
                            Sucesiones
                        </NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink
                            className={iconTabs === "7" ? "active" : ""}
                            href="#"
                            style={{border: "1px solid #0a006c"}}
                            onClick={e => {
                            e.preventDefault();
                            setIconTabs("7");
                            }}
                        >
                            <i className="now-ui-icons files_box"></i>
                            Otros
                        </NavLink>
                        </NavItem>
                    </Nav>
                    {/* </CardHeader>
                    <CardBody> */}
                    {/*
                    .########..########..######...####..######..########.########...#######.
                    .##.....##.##.......##....##...##..##....##....##....##.....##.##.....##
                    .##.....##.##.......##.........##..##..........##....##.....##.##.....##
                    .########..######...##...####..##...######.....##....########..##.....##
                    .##...##...##.......##....##...##........##....##....##...##...##.....##
                    .##....##..##.......##....##...##..##....##....##....##....##..##.....##
                    .##.....##.########..######...####..######.....##....##.....##..#######.
                    */}
                    <TabContent
                        className="text-center"
                        activeTab={"iconTabs" + iconTabs}
                    >
                    <TabPane tabId="iconTabs1">
                        <Row>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                    <div className="card-avatar">
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <img
                                            alt="Pie de bebe firmando documento de nacimiento"
                                            className="img img-raised"
                                            src={require("assets/img/Notaria/re1.jpg")}
                                        ></img>
                                        </a>
                                    </div>
                                    <CardBody>
                                        <CardTitle tag="h3">REGISTRO CIVIL DE NACIMIENTO</CardTitle>
                                            <Button
                                                className="btn-round"
                                                color="azul"
                                                onClick={() => setModal1(true)}
                                            >
                                                <b style={{fontSize: "16px"}}>Ver mas</b>
                                            </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                    <div className="card-avatar">
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <img
                                            alt="Dos manos de una mujer firmando un documento"
                                            className="img img-raised"
                                            src={require("assets/img/Notaria/re2.jpg")}
                                        ></img>
                                        </a>
                                    </div>
                                    <CardBody>
                                        <CardTitle tag="h3">REGISTRO CIVIL DE MATRIMONIO</CardTitle>
                                            <Button
                                                className="btn-round"
                                                color="azul"
                                                onClick={() => setModal2(true)}
                                            >
                                                <b style={{fontSize: "16px"}}>Ver mas</b>
                                            </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                    <div className="card-avatar">
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <img
                                            alt="Dos manos de un hombre firmando un documento"
                                            className="img img-raised"
                                            src={require("assets/img/Notaria/re3.jpg")}
                                        ></img>
                                        </a>
                                    </div>
                                    <CardBody>
                                        <CardTitle tag="h3">REGISTRO CIVIL DE DEFUNCIÓN</CardTitle>
                                            <Button
                                                className="btn-round"
                                                color="azul"
                                                onClick={() => setModal3(true)}
                                            >
                                                <b style={{fontSize: "16px"}}>Ver mas</b>
                                            </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                    <div className="card-avatar">
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <img
                                            alt="Varios nombres de personasa en un fondo blanco"
                                            className="img img-raised"
                                            src={require("assets/img/Notaria/re4.jpg")}
                                        ></img>
                                        </a>
                                    </div>
                                    <CardBody>
                                        <CardTitle tag="h3">CAMBIO DE NOMBRE</CardTitle>
                                            <Button
                                                className="btn-round"
                                                color="azul"
                                                onClick={() => setModal4(true)}
                                            >
                                                <b style={{fontSize: "16px"}}>Ver mas</b>
                                            </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        </TabPane>
                        {/* /*
                        .##.....##....###....########.########..####.##.....##..#######..##....##.####..#######.
                        .###...###...##.##......##....##.....##..##..###...###.##.....##.###...##..##..##.....##
                        .####.####..##...##.....##....##.....##..##..####.####.##.....##.####..##..##..##.....##
                        .##.###.##.##.....##....##....########...##..##.###.##.##.....##.##.##.##..##..##.....##
                        .##.....##.#########....##....##...##....##..##.....##.##.....##.##..####..##..##.....##
                        .##.....##.##.....##....##....##....##...##..##.....##.##.....##.##...###..##..##.....##
                        .##.....##.##.....##....##....##.....##.####.##.....##..#######..##....##.####..#######.
                        */ }
                        <TabPane tabId="iconTabs2">
                            <Row>
                                <Col md="4">
                                    <Card className="card-profile card-plain">
                                        <div className="card-avatar">
                                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                            <img
                                                alt="Dos anillos y un ramo"
                                                className="img img-raised"
                                                src={require("assets/img/Notaria/re5.jpg")}
                                            ></img>
                                            </a>
                                        </div>
                                        <CardBody>
                                            <CardTitle tag="h3">MATRIMONIO</CardTitle>
                                                <Button
                                                    className="btn-round"
                                                    color="azul"
                                                    onClick={() => setModal6(true)}
                                                >
                                                    <b style={{fontSize: "16px"}}>Ver mas</b>
                                                </Button>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="4">
                                    <Card className="card-profile card-plain">
                                        <div className="card-avatar">
                                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                            <img
                                                alt="Una pareja agarrados de la mano caminando"
                                                className="img img-raised"
                                                src={require("assets/img/Notaria/re6.jpg")}
                                            ></img>
                                            </a>
                                        </div>
                                        <CardBody>
                                            <CardTitle tag="h3">DECLARACIÓN DE UNIÓN MARITAL DE HECHO</CardTitle>
                                                <Button
                                                    className="btn-round"
                                                    color="azul"
                                                    onClick={() => setModal7(true)}
                                                >
                                                    <b style={{fontSize: "16px"}}>Ver mas</b>
                                                </Button>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="4">
                                    <Card className="card-profile card-plain">
                                        <div className="card-avatar">
                                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                            <img
                                                alt="Una pareja debatiendo"
                                                className="img img-raised"
                                                src={require("assets/img/Notaria/re7.jpg")}
                                            ></img>
                                            </a>
                                        </div>
                                        <CardBody>
                                            <CardTitle tag="h3">CAPITULACIONES MATRIMONIALES</CardTitle>
                                                <Button
                                                    className="btn-round"
                                                    color="azul"
                                                    onClick={() => setModal8(true)}
                                                >
                                                    <b style={{fontSize: "16px"}}>Ver mas</b>
                                                </Button>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                       {/*
                       ....########..######...######..########..####.########.##.....##.########.....###.....######..####.##....##
                       ....##.......##....##.##....##.##.....##..##.....##....##.....##.##.....##...##.##...##....##..##..###...##
                       ....##.......##.......##.......##.....##..##.....##....##.....##.##.....##..##...##..##........##..####..##
                       ....######....######..##.......########...##.....##....##.....##.########..##.....##.##........##..##.##.##
                       ....##.............##.##.......##...##....##.....##....##.....##.##...##...#########.##........##..##..####
                       ....##.......##....##.##....##.##....##...##.....##....##.....##.##....##..##.....##.##....##..##..##...###
                       ....########..######...######..##.....##.####....##.....#######..##.....##.##.....##..######..####.##....##
                       */}
                        <TabPane tabId="iconTabs3">
                        <Row>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                    <div className="card-avatar">
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <img
                                            alt="Una mano de un hombre firmando un documento"
                                            className="img img-raised"
                                            src={require("assets/img/Notaria/re8.jpg")}
                                        ></img>
                                        </a>
                                    </div>
                                    <CardBody>
                                        <CardTitle tag="h3">ESCRITURACIÓN</CardTitle>
                                            <Button
                                                className="btn-round"
                                                color="azul"
                                                onClick={() => setModal9(true)}
                                            >
                                                <b style={{fontSize: "16px"}}>Ver mas</b>
                                            </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                    <div className="card-avatar">
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <img
                                            alt="Dos personas dialogando y una casa en frente"
                                            className="img img-raised"
                                            src={require("assets/img/Notaria/re9.jpg")}
                                        ></img>
                                        </a>
                                    </div>
                                    <CardBody>
                                        <CardTitle tag="h3">PROMESA DE COMPRAVENTA DE UN INMUEBLE</CardTitle>
                                            <Button
                                                className="btn-round"
                                                color="azul"
                                                onClick={() => setModal10(true)}
                                            >
                                                <b style={{fontSize: "16px"}}>Ver mas</b>
                                            </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                    <div className="card-avatar">
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <img
                                            alt="tres personas aceptando un negocio con una casa al frente"
                                            className="img img-raised"
                                            src={require("assets/img/Notaria/re10.jpg")}
                                        ></img>
                                        </a>
                                    </div>
                                    <CardBody>
                                        <CardTitle tag="h3">COMPRAVENTA DE INMUEBLES</CardTitle>
                                            <Button
                                                className="btn-round"
                                                color="azul"
                                                onClick={() => setModal11(true)}
                                            >
                                                <b style={{fontSize: "16px"}}>Ver mas</b>
                                            </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                    <div className="card-avatar">
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <img
                                            alt="Dos manos apretandose"
                                            className="img img-raised"
                                            src={require("assets/img/Notaria/re11.jpg")}
                                        ></img>
                                        </a>
                                    </div>
                                    <CardBody>
                                        <CardTitle tag="h3">PERMUTA DE INMUEBLES</CardTitle>
                                            <Button
                                                className="btn-round"
                                                color="azul"
                                                onClick={() => setModal12(true)}
                                            >
                                                <b style={{fontSize: "16px"}}>Ver mas</b>
                                            </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        </TabPane>
                        {/*
                        ....###....##.....##.########.########.##....##.########.####..######.....###.....######..####.##....##
                        ...##.##...##.....##....##....##.......###...##....##.....##..##....##...##.##...##....##..##..###...##
                        ..##...##..##.....##....##....##.......####..##....##.....##..##........##...##..##........##..####..##
                        .##.....##.##.....##....##....######...##.##.##....##.....##..##.......##.....##.##........##..##.##.##
                        .#########.##.....##....##....##.......##..####....##.....##..##.......#########.##........##..##..####
                        .##.....##.##.....##....##....##.......##...###....##.....##..##....##.##.....##.##....##..##..##...###
                        .##.....##..#######.....##....########.##....##....##....####..######..##.....##..######..####.##....##
                        */}
                        <TabPane tabId="iconTabs4">
                        <Row>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                    <div className="card-avatar">
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <img
                                            alt="Una persona poniendo un sello"
                                            className="img img-raised"
                                            src={require("assets/img/Notaria/re12.jpg")}
                                        ></img>
                                        </a>
                                    </div>
                                    <CardBody>
                                        <CardTitle tag="h3">AUTENTICACIÓN</CardTitle>
                                            <Button
                                                className="btn-round"
                                                color="azul"
                                                onClick={() => setModal13(true)}
                                            >
                                                <b style={{fontSize: "16px"}}>Ver mas</b>
                                            </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                    <div className="card-avatar">
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <img
                                            alt="Una persona firmando un documento"
                                            className="img img-raised"
                                            src={require("assets/img/Notaria/re13.jpg")}
                                        ></img>
                                        </a>
                                    </div>
                                    <CardBody>
                                        <CardTitle tag="h3">RECONOCIMIENTO DE FIRMA Y CONTENIDO</CardTitle>
                                            <Button
                                                className="btn-round"
                                                color="azul"
                                                onClick={() => setModal14(true)}
                                            >
                                                <b style={{fontSize: "16px"}}>Ver mas</b>
                                            </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                    <div className="card-avatar">
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <img
                                            alt="Una persona explicando el documento a firmar a otra"
                                            className="img img-raised"
                                            src={require("assets/img/Notaria/re14.jpg")}
                                        ></img>
                                        </a>
                                    </div>
                                    <CardBody>
                                        <CardTitle tag="h3">FIRMA A RUEGO - PERSONAS QUE NO SABEN O NO PUEDEN FIRMAR</CardTitle>
                                            <Button
                                                className="btn-round"
                                                color="azul"
                                                onClick={() => setModal15(true)}
                                            >
                                                <b style={{fontSize: "16px"}}>Ver mas</b>
                                            </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {/*
                        .########..########..######..##..........###....########.....###.....######..####..#######..##....##.########..######.
                        .##.....##.##.......##....##.##.........##.##...##.....##...##.##...##....##..##..##.....##.###...##.##.......##....##
                        .##.....##.##.......##.......##........##...##..##.....##..##...##..##........##..##.....##.####..##.##.......##......
                        .##.....##.######...##.......##.......##.....##.########..##.....##.##........##..##.....##.##.##.##.######....######.
                        .##.....##.##.......##.......##.......#########.##...##...#########.##........##..##.....##.##..####.##.............##
                        .##.....##.##.......##....##.##.......##.....##.##....##..##.....##.##....##..##..##.....##.##...###.##.......##....##
                        .########..########..######..########.##.....##.##.....##.##.....##..######..####..#######..##....##.########..######.
                        */}
                        </TabPane>
                        <TabPane tabId="iconTabs5">
                        <Row>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                    <div className="card-avatar">
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <img
                                            alt="Una persona leyendo un documento a otras"
                                            className="img img-raised"
                                            src={require("assets/img/Notaria/re15.jpg")}
                                        ></img>
                                        </a>
                                    </div>
                                    <CardBody>
                                        <CardTitle tag="h3">EXTRA PROCESO O DECLARACIONES BAJO GRAVEDAD DE JURAMENTO</CardTitle>
                                            <Button
                                                className="btn-round"
                                                color="azul"
                                                onClick={() => setModal16(true)}
                                            >
                                                <b style={{fontSize: "16px"}}>Ver mas</b>
                                            </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                    <div className="card-avatar">
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <img
                                            alt="una persona firmando un documento"
                                            className="img img-raised"
                                            src={require("assets/img/Notaria/re16.jpg")}
                                        ></img>
                                        </a>
                                    </div>
                                    <CardBody>
                                        <CardTitle tag="h3">PODER</CardTitle>
                                            <Button
                                                className="btn-round"
                                                color="azul"
                                                onClick={() => setModal17(true)}
                                            >
                                                <b style={{fontSize: "16px"}}>Ver mas</b>
                                            </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        </TabPane>
                         {/*
                        ..######..##.....##..######..########..######..####..#######..##....##.########..######.
                        .##....##.##.....##.##....##.##.......##....##..##..##.....##.###...##.##.......##....##
                        .##.......##.....##.##.......##.......##........##..##.....##.####..##.##.......##......
                        ..######..##.....##.##.......######....######...##..##.....##.##.##.##.######....######.
                        .......##.##.....##.##.......##.............##..##..##.....##.##..####.##.............##
                        .##....##.##.....##.##....##.##.......##....##..##..##.....##.##...###.##.......##....##
                        ..######...#######...######..########..######..####..#######..##....##.########..######.
                        */}
                        <TabPane tabId="iconTabs6">
                        <Row>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                    <div className="card-avatar">
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <img
                                            alt="Varias personas firmando un documento"
                                            className="img img-raised"
                                            src={require("assets/img/Notaria/re17.jpg")}
                                        ></img>
                                        </a>
                                    </div>
                                    <CardBody>
                                        <CardTitle tag="h3">SUCESIÓN DE BIENES POR CAUSA DE MUERTE</CardTitle>
                                            <Button
                                                className="btn-round"
                                                color="azul"
                                                onClick={() => setModal18(true)}
                                            >
                                                <b style={{fontSize: "16px"}}>Ver mas</b>
                                            </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                    <div className="card-avatar">
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <img
                                            alt="Un documento con varios lapiceros al rededor"
                                            className="img img-raised"
                                            src={require("assets/img/Notaria/re18.jpg")}
                                        ></img>
                                        </a>
                                    </div>
                                    <CardBody>
                                        <CardTitle tag="h3">TESTAMENTO</CardTitle>
                                            <Button
                                                className="btn-round"
                                                color="azul"
                                                onClick={() => setModal19(true)}
                                            >
                                                <b style={{fontSize: "16px"}}>Ver mas</b>
                                            </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        </TabPane>
                        {/*
                        ..#######..########.########...#######...######.
                        .##.....##....##....##.....##.##.....##.##....##
                        .##.....##....##....##.....##.##.....##.##......
                        .##.....##....##....########..##.....##..######.
                        .##.....##....##....##...##...##.....##.......##
                        .##.....##....##....##....##..##.....##.##....##
                        ..#######.....##....##.....##..#######...######.
                        */}
                        <TabPane tabId="iconTabs7">
                        <Row>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                    <div className="card-avatar">
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <img
                                            alt="Dos manos de un adultyo y un bebe sosteniendo unas monedas"
                                            className="img img-raised"
                                            src={require("assets/img/Notaria/re19.jpg")}
                                        ></img>
                                        </a>
                                    </div>
                                    <CardBody>
                                        <CardTitle tag="h3">DONACIÓN</CardTitle>
                                            <Button
                                                className="btn-round"
                                                color="azul"
                                                onClick={() => setModal20(true)}
                                            >
                                                <b style={{fontSize: "16px"}}>Ver mas</b>
                                            </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                    <div className="card-avatar">
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <img
                                            alt="Una persona firmando un documento con una casa al lado"
                                            className="img img-raised"
                                            src={require("assets/img/Notaria/re20.jpg")}
                                        ></img>
                                        </a>
                                    </div>
                                    <CardBody>
                                        <CardTitle tag="h3">CONSTITUCIÓN DE HIPOTECA</CardTitle>
                                            <Button
                                                className="btn-round"
                                                color="azul"
                                                onClick={() => setModal21(true)}
                                            >
                                                <b style={{fontSize: "16px"}}>Ver mas</b>
                                            </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                    <div className="card-avatar">
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <img
                                            alt="Unas llaves encima de un documento"
                                            className="img img-raised"
                                            src={require("assets/img/Notaria/re21.jpg")}
                                        ></img>
                                        </a>
                                    </div>
                                    <CardBody>
                                        <CardTitle tag="h3">CANCELACIÓN DE HIPOTECA</CardTitle>
                                            <Button
                                                className="btn-round"
                                                color="azul"
                                                onClick={() => setModal22(true)}
                                            >
                                                <b style={{fontSize: "16px"}}>Ver mas</b>
                                            </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                    <div className="card-avatar">
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <img
                                            alt="Dos manos tocando una casa pequeña"
                                            className="img img-raised"
                                            src={require("assets/img/Notaria/re22.jpg")}
                                        ></img>
                                        </a>
                                    </div>
                                    <CardBody>
                                        <CardTitle tag="h3">AFECTACIÓN A VIVIENDA FAMILIAR</CardTitle>
                                            <Button
                                                className="btn-round"
                                                color="azul"
                                                onClick={() => setModal23(true)}
                                            >
                                                <b style={{fontSize: "16px"}}>Ver mas</b>
                                            </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                    <div className="card-avatar">
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <img
                                            alt="Una persona jugando Jenga con una casa encima del juego de mesa"
                                            className="img img-raised"
                                            src={require("assets/img/Notaria/re23.jpg")}
                                        ></img>
                                        </a>
                                    </div>
                                    <CardBody>
                                        <CardTitle tag="h3">PATRIMONIO DE FAMILIA INEMBARGABLE</CardTitle>
                                            <Button
                                                className="btn-round"
                                                color="azul"
                                                onClick={() => setModal5(true)}
                                            >
                                                <b style={{fontSize: "16px"}}>Ver mas</b>
                                            </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        </TabPane>
                    </TabContent>
                {/* </CardBody>
            </Card> */}
           </Col>
          </Row>
        </Container>
      </div>
      {/* /*
      .##.....##..#######..########.....###....##......
      .###...###.##.....##.##.....##...##.##...##......
      .####.####.##.....##.##.....##..##...##..##......
      .##.###.##.##.....##.##.....##.##.....##.##......
      .##.....##.##.....##.##.....##.#########.##......
      .##.....##.##.....##.##.....##.##.....##.##......
      .##.....##..#######..########..##.....##.########
      */}

      {/*/*
      {/*.########..########..######...####..######..########.########...#######......######..####.##.....##.####.##..........########..########....##....##....###.....######..####.##.....##.####.########.##....##.########..#######.
      {/*.##.....##.##.......##....##...##..##....##....##....##.....##.##.....##....##....##..##..##.....##..##..##..........##.....##.##..........###...##...##.##...##....##..##..###...###..##..##.......###...##....##....##.....##
      {/*.##.....##.##.......##.........##..##..........##....##.....##.##.....##....##........##..##.....##..##..##..........##.....##.##..........####..##..##...##..##........##..####.####..##..##.......####..##....##....##.....##
      {/*.########..######...##...####..##...######.....##....########..##.....##....##........##..##.....##..##..##..........##.....##.######......##.##.##.##.....##.##........##..##.###.##..##..######...##.##.##....##....##.....##
      {/*.##...##...##.......##....##...##........##....##....##...##...##.....##....##........##...##...##...##..##..........##.....##.##..........##..####.#########.##........##..##.....##..##..##.......##..####....##....##.....##
      {/*.##....##..##.......##....##...##..##....##....##....##....##..##.....##....##....##..##....##.##....##..##..........##.....##.##..........##...###.##.....##.##....##..##..##.....##..##..##.......##...###....##....##.....##
      {/*.##.....##.########..######...####..######.....##....##.....##..#######......######..####....###....####.########....########..########....##....##.##.....##..######..####.##.....##.####.########.##....##....##.....#######.
      {/**/}

      <Modal isOpen={Modal1} toggle={() => setModal1(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div  className="text-center" style={{backgroundColor: "White", borderRadius: "15px", border: "3px solid #979797"}}>
          <div className="modal-header text-center">
              <h3> </h3>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => setModal1(false)}
            >
              <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i> </span>
            </button>
          </div>
          <ModalBody>
            <h1 className="text-center title">
                <b style={{color : "#1c6e38"}}>REGISTRO CIVIL </b>DE NACIMIENTO<br></br>
            </h1>
            <h5 className="description text-justify" style={{color: "Black"}}>
            <b>Es un documento indispensable mediante el cual la persona prueba ante 
                la familia y la sociedad su existencia y le permite identificarse, 
                ejercer sus derechos y cumplir sus obligaciones.</b>
            </h5>
            <h3 className="azul-title text-center">Requisitos:</h3>
            <ol>
                <li>
                    <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Certificado de nacido vivo dado por el médico, centro hospitalario, 
                        enfermera o persona que haya ayudado a la madre en el parto.
                            Si se trata de un menor que pertenece a una comunidad indígena, 
                            puede servir como documento la autorización indigena.</b>
                    </h5>
                </li>
                <li>
                    <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>En caso de no contar con el anterior certificado, se requiere declaración bajo juramento de 
                        dos testigos que hayan presenciado el nacimiento o hayan tenido conocimiento de él.</b>
                    </h5>
                </li>
                <li>
                    <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Documentos de identificación de los padres o de las personas 
                        que estén autorizadas por la ley para realizar el registro, como abuelos, tíos o parientes mayores.</b>
                    </h5>
                </li>
                <li>
                    <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>El registro que se hace después del mes de nacido es extemporáneo y las personas 
                        que no cuenten con ninguno de los anteriores requisitos, pueden presentar la partida de bautismo católico o la anotación de origen religioso, si es de otro credo reconocido por el Estado.</b>
                    </h5>
                </li>
            </ol>
            <h4 className="description text-justify" style={{color: "Black"}}>
                <b>Tiempo estimado de entrega: </b>El mismo día.
            </h4>
            <h4 className="description text-justify" style={{color: "Black"}}>
                <b>Costo: </b>La inscripción en el registro no tiene costo. Las copias, según tarifa notarial.
            </h4>
          </ModalBody>
          <ModalFooter>
            <Button
                color="azul"
                type="button"
                onClick={() => setModal1(false)}
                className="text-center"
            >
                <b style={{fontSize: "17px"}}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>

     {/*
     .########......##.....##....###....########.########..####.##.....##..#######..##....##.####..#######.
     .##.....##.....###...###...##.##......##....##.....##..##..###...###.##.....##.###...##..##..##.....##
     .##.....##.....####.####..##...##.....##....##.....##..##..####.####.##.....##.####..##..##..##.....##
     .########......##.###.##.##.....##....##....########...##..##.###.##.##.....##.##.##.##..##..##.....##
     .##...##.......##.....##.#########....##....##...##....##..##.....##.##.....##.##..####..##..##.....##
     .##....##..###.##.....##.##.....##....##....##....##...##..##.....##.##.....##.##...###..##..##.....##
     .##.....##.###.##.....##.##.....##....##....##.....##.####.##.....##..#######..##....##.####..#######.
     */}

      <Modal isOpen={Modal2} toggle={() => setModal2(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{backgroundColor: "White",borderRadius: "15px", border: "3px solid #979797"}}>
          <div className="modal-header">
            <h3 className="title text-center" style={{color:"#18ce0f"}} id="exampleModalLiveLabel">
            </h3>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => setModal2(false)}
            >
              <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
            </button>
          </div>
          <ModalBody>
            <h1 className="text-center title">
                <b style={{color : "#1c6e38"}}>REGISTRO CIVIL </b>DE MATRIMONIO<br></br>
            </h1>
          <h5 className="description text-justify" style={{color: "Black"}}>
            <b>Es el documento público necesario para probar que una persona está casada. 
                En él se registran los datos de quienes se casan y el acto del matrimonio. 
                Sirve para adquirir derechos y cumplir obligaciones entre sí, ante la familia, las autoridades y la sociedad.</b>
            </h5>
            <h3 className="azul-title text-center">Requisitos:</h3>
            <ol>
                <li>
                    <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Copia auténtica del registro civil de nacimiento de quienes se casan.</b>
                    </h5>
                </li>
                <li>
                    <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Documento de identificación de quienes se casan.</b>
                    </h5>
                </li>
                <li>
                    <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Si el matrimonio fue católico. copia auténtica de la partida parroquial.</b>
                    </h5>
                </li>
                <li>
                    <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Si el matrimonio fue de otra religión, copia auténtica dada por la confesión
                            religiosa debidamente reconocida por el Estado.</b>
                    </h5>
                </li>
                <li>
                    <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Si el matrimonio se realizó ante juez, acta del matrimonio debidamente protocolizada en notaría.</b>
                    </h5>
                </li>
                <li>
                    <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Si el matrimonio fue celebrado en el exterior, hay requisitos adicionales que deben consultarse con el notario.</b>
                    </h5>
                </li>
            </ol>
            <h4 className="description text-justify" style={{color: "Black"}}>
                <b>Tiempo estimado de entrega: </b>El mismo día.
            </h4>
            <h4 className="description text-justify" style={{color: "Black"}}>
                <b>Costo: </b>La inscripción en el registro no tiene costo. Las copias, según tarifa notarial.
            </h4>
          </ModalBody>
          <ModalFooter>
            <Button
                color="azul"
                type="button"
                onClick={() => setModal2(false)}
                className="text-center"
            >
                <b style={{fontSize: "17px"}}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>

       {/*
       .########..########.########.##.....##.##....##..######..####..#######..##....##
       .##.....##.##.......##.......##.....##.###...##.##....##..##..##.....##.###...##
       .##.....##.##.......##.......##.....##.####..##.##........##..##.....##.####..##
       .##.....##.######...######...##.....##.##.##.##.##........##..##.....##.##.##.##
       .##.....##.##.......##.......##.....##.##..####.##........##..##.....##.##..####
       .##.....##.##.......##.......##.....##.##...###.##....##..##..##.....##.##...###
       .########..########.##........#######..##....##..######..####..#######..##....##
       */}
       <Modal isOpen={Modal3} toggle={() => setModal3(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{backgroundColor: "White" ,borderRadius: "15px", border: "3px solid #979797"}}>
          <div className="modal-header">
            <h3> </h3>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => setModal3(false)}
            >
              <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
            </button>
          </div>
          <ModalBody>
            <h1 className="text-center title">
                <b style={{color : "#1c6e38"}}>REGISTRO CIVIL </b>DE DEFUNCIÓN<br></br>
            </h1> 
            <h5 className="description text-justify" style={{color: "Black"}}>
            <b>Es el documento público que prueba el hecho de la muerte y su causa. Sirve para que la defunción 
                quede anotada en el registro civil de nacimiento y en el registro civil de matrimonio, si lo hubo. 
                También permite el inicio de los trámites de sucesión. Además, la diligencia de defunción la comunica el 
                notario a la Registraduría Nacional del Estado Civil para cancelar la cédula de ciudadanía.</b>
            </h5>
            <h3 className="azul-title text-center">Requisitos:</h3>
            <ol>
                <li>
                    <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Denuncio de la defunción dentro de los dos días después de haber conocido el fallecimiento. 
                        De haber excedido este plazo, debe presentar orden impartida por el inspector de policía, 
                        previa solicitud escrita del interesado donde explica las causas del retraso.</b>
                    </h5>
                </li>
                <li>
                    <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Certificado médico de defunción o declaración de dos testigos cuando no haya médico en la localidad.</b>
                    </h5>
                </li>
                <li>
                    <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Copia de la cédula de ciudadanía de quien registra el fallecimiento.</b>
                    </h5>
                </li>
                <li>
                    <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Orden judicial, si la muerte fue violenta o se desconocen los motivos. 
                        Para el caso de muerte presunta por desaparecimiento, sentencia judicial en firme.</b>
                    </h5>
                </li>
            </ol>
            <h4 className="description text-justify" style={{color: "Black"}}>
                <b>Tiempo estimado de entrega: </b>El mismo día.
            </h4>
            <h4 className="description text-justify" style={{color: "Black"}}>
                <b>Costo: </b>La inscripción en el registro no tiene costo. Las copias, según tarifa notarial.
            </h4>
          </ModalBody>
          <ModalFooter>
            <Button
                color="azul"
                type="button"
                onClick={() => setModal3(false)}
                className="text-center"
            >
                <b style={{fontSize: "17px"}}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      {/*
      ..######.........##....##..#######..##.....##.########..########..########
      .##....##........###...##.##.....##.###...###.##.....##.##.....##.##......
      .##..............####..##.##.....##.####.####.##.....##.##.....##.##......
      .##..............##.##.##.##.....##.##.###.##.########..########..######..
      .##..............##..####.##.....##.##.....##.##.....##.##...##...##......
      .##....##.###....##...###.##.....##.##.....##.##.....##.##....##..##......
      ..######..###....##....##..#######..##.....##.########..##.....##.########
      */}
      <Modal isOpen={Modal4} toggle={() => setModal4(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{backgroundColor: "White" ,borderRadius: "15px", border: "3px solid #979797"}}>
        <div className="modal-header">
            <h3> </h3>
            <button
            aria-label="Close"
            className="close"
            type="button"
            onClick={() => setModal4(false)}
            >
            <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
            </button>
        </div>
        <ModalBody>
            <h1 className="text-center title">
                <b style={{color : "#1c6e38"}}>CAMBIO </b>DE NOMBRE<br></br>
            </h1>
            <h5 className="description text-center" style={{color: "Black"}}>
            <b>El cambio de nombre lo podrá hacer la persona mayor de edad voluntariamente o los padres en 
                representación del menor de edad, para suprimir, adicionar o modificar sus nombres 
                o sus apellidos y por regla general, solo puede hacerse una vez por escritura pública 
                ante notario. Los nuevos cambios deben estar orientados a garantizar la identidad entre 
                el nombre y el género o evitar discriminaciones.</b>
            </h5>
            <h3 className="azul-title text-center">"ARTICULO 6°. El Articulo 94 del Decreto-Ley 1260 de 1970", quedará así:</h3>
            <h5 className="description text-justify" style={{color: "Black"}}>
            <b>ARTICULO 94. «Aparte subrayado CONDICIONALMENTE exequible» El propio inscrito podrá disponer, 
                por una sola vez, mediante escritura pública la modificación de registro, para sustituir,
                    rectificar, corregir o adicionar Su nombre, todo con el fin de fijar su identidad personal.</b>
            </h5>
            <h3 className="azul-title text-center">Corte Constitucional:</h3>
            <h5 className="description text-justify" style={{color: "Black"}}>
            <b> La expresión subrayada <u>por primera vez</u> declarada CONDICIONALMENTE EXEQUIBLE por la
                    Corte Constitucional mediante Sentencia C-114-17 de 22 de febrero de 2017 Magistrado 
                    Ponente Dr Alejandro Linares Cantillo. «Entendido de que tal restricción no será aplicable 
                    en aquellos eventos en que exista una justificación constitucional, clara y suficiente,de 
                    conformidad con los criterios establecidos en la parte motiva de esta sentencia».</b>
            </h5>
            <h3 className="azul-title text-center">Requisitos:</h3>
            
            <ol>
                <li>
                    <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Debe ser realizado directamente por la persona mayor de edad que quiere cambiarse el nombre y 
                        si el cambio es para un menor de edad, deben realizarlo sus padres o quien tenga la patria potestad.</b>
                    </h5>
                </li>
                <li>
                    <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Presentar documento de identificación y copia auténtica del registro civil de nacimiento que se va a modificar.</b>
                    </h5>
                </li>
                <li>
                    <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Si se trata de integrantes de comunidades o pueblos indígenas, deben presentar copia del registro 
                        civil de nacimiento y el permiso de la autoridad indigena reconocida por el Estado.</b>
                    </h5>
                </li>
                <li>
                    <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>La mujer casada podrá proceder, por medio de escritura pública, a adicionar o suprimir el apellido del marido 
                        precedido de la preposición "de", en los casos en que ella lo hubiere adoptado o hubiere sido establecido por la ley.</b>
                    </h5>
                </li>
            </ol>
            <h5 className="description text-justify" style={{color: "Black"}}>
                <b>El instrumento a que se refiere el presente artículo deberá inscribirse en el correspondiente registro civil del interesado,
                        para lo cual se procederá a la apertura de un nuevo folio. El original y el sustituto llevarán notas de recíproca referencia.</b>
            </h5>
            <h4 className="description text-justify" style={{color: "Black"}}>
                <b>Tiempo estimado de entrega: </b>El mismo día.
            </h4>
            <h4 className="description text-justify" style={{color: "Black"}}>
                <b>Costo: </b> Si. Según la tarifa notarial. Para miembros de comunidades indígenas, esta diligencia, incluidas las copias auténticas, no tienen Costo.
            </h4>
        </ModalBody>
        <ModalFooter>
            <Button
                color="azul"
                type="button"
                onClick={() => setModal4(false)}
                className="text-center"
            >
                <b style={{fontSize: "17px"}}>Cerrar</b>
            </Button>
        </ModalFooter>
        </div>
    </Modal>
    {/*
    .##.....##....###....########.########..####.##.....##..#######..##....##.####..#######.
    .###...###...##.##......##....##.....##..##..###...###.##.....##.###...##..##..##.....##
    .####.####..##...##.....##....##.....##..##..####.####.##.....##.####..##..##..##.....##
    .##.###.##.##.....##....##....########...##..##.###.##.##.....##.##.##.##..##..##.....##
    .##.....##.#########....##....##...##....##..##.....##.##.....##.##..####..##..##.....##
    .##.....##.##.....##....##....##....##...##..##.....##.##.....##.##...###..##..##.....##
    .##.....##.##.....##....##....##.....##.####.##.....##..#######..##....##.####..#######.
    */}
    <Modal isOpen={Modal6} toggle={() => setModal6(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{backgroundColor: "White" ,borderRadius: "15px", border: "3px solid #979797"}}>
        <div className="modal-header">
            <h3> </h3>
            <button
            aria-label="Close"
            className="close"
            type="button"
            onClick={() => setModal6(false)}
            >
            <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
            </button>
        </div>
        <ModalBody>
            <h1 className="text-center title">
                <b style={{color : "#1c6e38"}}>MATRIMONIO</b><br></br>
            </h1>
            <h5 className="description text-justify" style={{color: "Black"}}>
                <b>Es un contrato solemne celebrado ante juez o notario, por el cual se unen legalmente 
                    un hombre y una mujer o dos personas del mismo sexo, con el fin de vivir juntos, 
                    auxiliarse mutuamente y constituir una familia. Por el hecho del matrimonio, nace 
                    la sociedad conyugal o sociedad de los bienes adquiridos dentro del matrimonio. 
                    La diligencia de matrimonio termina con la firma del notario denominada autorización 
                    notarial en la escritura pública.</b>
            </h5>
            <h3 className="azul-title text-center">Requisitos:</h3>
            <Nav className="justify-content-center" role="tablist" tabs  color= "#0a006c">
                <NavItem>
                <NavLink
                    className={iconTabs2 === "1" ? "active" : ""}
                    href="#"
                    style={{border: "1px solid #0a006c"}}
                    onClick={e => {
                    e.preventDefault();
                    setIconTabs2("1");
                    }}
                >
                    <i className="now-ui-icons ui-2_favourite-28"></i>
                    PRIMERA VEZ
                </NavLink>
                </NavItem>
                <NavItem>
                <NavLink
                    className={iconTabs2 === "2" ? "active" : ""}
                    href="#"
                    style={{border: "1px solid #0a006c"}}
                    onClick={e => {
                    e.preventDefault();
                    setIconTabs2("2");
                    }}
                >
                    <i className="now-ui-icons ui-2_favourite-28"></i>
                    DIVORCIADOS(AS)
                </NavLink>
                </NavItem>
                <NavItem>
                <NavLink
                    className={iconTabs2 === "3" ? "active" : ""}
                    href="#"
                    style={{border: "1px solid #0a006c"}}
                    onClick={e => {
                    e.preventDefault();
                    setIconTabs2("3");
                    }}
                >
                    <i className="now-ui-icons ui-2_favourite-28"></i>
                    VIUDO (A)
                </NavLink>
                </NavItem>
                <NavItem>
                <NavLink
                    className={iconTabs2 === "4" ? "active" : ""}
                    href="#"
                    style={{border: "1px solid #0a006c"}}
                    onClick={e => {
                    e.preventDefault();
                    setIconTabs2("4");
                    }}
                >
                    <i className="now-ui-icons ui-2_favourite-28"></i>
                    MENOR DE EDAD
                </NavLink>
                </NavItem>
                <NavItem>
                <NavLink
                    className={iconTabs2 === "5" ? "active" : ""}
                    href="#"
                    style={{border: "1px solid #0a006c"}}
                    onClick={e => {
                    e.preventDefault();
                    setIconTabs2("5");
                    }}
                >
                    <i className="now-ui-icons ui-2_favourite-28"></i>
                    CON EXTRANJERO(A)
                </NavLink>
                </NavItem>
            </Nav>
            {/* <center>
                <a href={require("../../assets/pdf/REQUISITOS PARA CONTRAER MATRIMONIO CIVIL.pdf")} style={{textDecoration: "none !important"}} target="_black">
                <img
                alt="..."
                width="80px"
                src={require("assets/img/Notaria/PDF.png")}
                ></img>
                <h6>REQUISITOS</h6>
                </a>
            </center> */}

            <TabContent
                className="text-center"
                activeTab={"iconTabs2" + iconTabs2}
            >

                <TabPane tabId="iconTabs21">
                <ol>
                    <b>PRIMERA VEZ</b>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Fotocopias de la Cedula de Ciudadanía, preferiblemente ampliadas al (150%).</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Registros civiles de Nacimiento original (Copia del folio – copia auténtica) 
                            con menos de TRES (3) meses de expedido con la nota “VALIDO PARA MATRIMONIO” 
                            o PARA “ACREDITAR PARENTESCO”</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>En caso tal de que tengan hijos(as) menores de edad en común, deben aportar 
                            Copia auténtica del Registro Civil de Nacimiento de los(las) hijos(as) con 
                            menos de TRES (3) meses de expedido con la nota válido PARA “ACREDITAR PARENTESCO”.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>En caso de que los(las) contrayentes tengan hijos(as) menores de edad, que no son en común, 
                            deberán realizar inventario solemne de bienes por medio de juzgado ya que es una ley que 
                            rige para nacionales o extranjeros que desean contraer matrimonio en este país, 
                            para lo cual deberá asesorarse de un abogado o tramitar solicitud ante notaria.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Copia auténtica de la Escritura Pública de Capitulaciones ( En caso de que los(las) contrayentes
                            hayan realizado capitulaciones) y posteriormente deben estar inscritas en el Registro Civil de Nacimiento.</b>
                        </h5>
                    </li>
                </ol>

                </TabPane>
                <TabPane tabId="iconTabs22">
                <ol>
                    <b>DIVORCIADOS(AS)</b>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Fotocopias de la Cedula de Ciudadanía, preferiblemente ampliadas al (150%).</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Copia auténtica del Registro Civil de Nacimiento con menos de TRES (3) meses de 
                            expedido con la nota “VALIDO PARA MATRIMONIO” o PARA “ACREDITAR PARENTESCO”, 
                            con su respectiva nota marginal de divorcio y liquidación de la sociedad conyugal 
                            y número de libro de varios donde conste dicho divorcio. 
                            Copia Autentica del Libro de Varios donde conste dicho divorcio.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>En caso tal de que tengan hijos(as) menores de edad en común, deben aportar 
                            Copia auténtica del Registro Civil de Nacimiento de los(las) hijos(as) con 
                            menos de TRES (3) meses de expedido con la nota válido PARA “ACREDITAR PARENTESCO”.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>En caso de que los(las) contrayentes tengan hijos(as) menores de edad, 
                            que no son en común, deberán realizar inventario solemne de bienes por 
                            medio de juzgado ya que es una ley que rige para nacionales o extranjeros que 
                            desean contraer matrimonio en este país, para lo 
                            cual deberá asesorarse de un abogado o tramitar solicitud ante notaria.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Copia auténtica de la Escritura Pública de Capitulaciones ( En caso de que los(las) 
                            contrayentes hayan realizado capitulaciones) y 
                            posteriormente deben estar inscritas en el Registro Civil de Nacimiento.</b>
                        </h5>
                    </li>
                </ol>

                </TabPane>
                <TabPane tabId="iconTabs23">
                <ol>
                    <b>VIUDO (A)</b>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Fotocopias de la Cedula de Ciudadanía, preferiblemente ampliadas al (150%).</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Copia auténtica del Registro Civil de nacimiento con menos de TRES (3) 
                            meses de expedido con la nota “VALIDO PARA MATRIMONIO” o PARA “ACREDITAR PARENTESCO”.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>En caso tal de que tengan hijos(as) menores de edad en común, deben aportar Copia 
                            auténtica del Registro Civil de Nacimiento de los(las) hijos(as) con menos de TRES (3) 
                            meses de expedido con la nota válido PARA “ACREDITAR PARENTESCO”.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>En caso de que los(las) contrayentes tengan hijos(as) menores de edad, que no son en común,
                            deberán realizar inventario solemne de bienes por medio de juzgado ya que es una ley que 
                            rige para nacionales o extranjeros que desean contraer matrimonio en este país, para lo 
                            cual deberá asesorarse de un abogado o tramitar solicitud ante notaria.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Copia auténtica del Registro Civil de Matrimonio.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Copia auténtica del Registro civil de Defunción.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Copia auténtica de la Escritura Pública de Capitulaciones ( En caso de que los(las)
                            contrayentes hayan realizado capitulaciones) y posteriormente deben estar inscritas 
                            en el Registro Civil de Nacimiento.</b>
                        </h5>
                    </li>
                </ol>

                </TabPane>
                <TabPane tabId="iconTabs24">
                <ol>
                    <b>MENOR DE EDAD</b>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Autorización por parte de ambos padres debidamente autenticado, 
                            en el cual especifiquen con quien permite que contraiga matrimonio el o la menor.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Copia auténtica del Registro Civil de nacimiento con menos de 
                            TRES (3) meses de expedido con la nota “VALIDO PARA MATRIMONIO” o PARA “ACREDITAR PARENTESCO”.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>En caso de que haya hijos menores de edad en común, deben aportar Copia auténtica del 
                            Registro Civil de Nacimiento de los hijos con la nota válida PARA “ACREDITAR PARENTESCO”.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>En caso de que los(las) contrayentes tengan hijos(as) menores de edad, que no son en común,
                            deberán realizar inventario solemne de bienes por medio de juzgado ya que es una ley que 
                            rige para nacionales o extranjeros que desean contraer matrimonio en este país, para lo 
                            cual deberá asesorarse de un abogado o tramitar solicitud ante notaria.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Fotocopia de la Tarjeta de Identidad, preferiblemente ampliada al 150%.</b>
                        </h5>
                    </li>
                </ol>

                </TabPane>
                <TabPane tabId="iconTabs25">
                <ol>
                    <b>CON EXTRANJERO(A)</b>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Fotocopia ampliada al 150% del Pasaporte</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Registro Civil de Nacimiento Original, completo (donde aparezca el 
                            nombre de los padres) con menos de TRES (3) meses de expedido.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Si es soltero(a) presentar un Certificado de soltería original proveniente de 
                            su país de origen, expedido por un funcionario público con menos de TRES (3) meses de expedido.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Si es viudo(a) deberá presentar registro civil de su primer 
                            matrimonio y registro civil de defunción del (la) cónyuge.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Si es Divorciado(a) deberá presentar la sentencia de divorcio original o constancia sobre la misma.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>En caso de que los(las) contrayentes tengan hijos menores de edad, que no son en común, 
                            deberán realizar inventario solemne de bienes por medio de juzgado ya que es una ley 
                            que rige para nacionales o extranjeros que desean contraer matrimonio en este país, 
                            para lo cual deberá asesorarse de un abogado o tramitar solicitud ante notaria.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>En caso tal de que tengan hijos(as) menores de edad en común, deben aportar Copia 
                            auténtica del Registro Civil de Nacimiento de los(las) hijos(as) con la nota válido 
                            PARA “ACREDITAR PARENTESCO”.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Si los documentos llegan en un idioma que no sea el castellano deberá 
                            hacerlos traducir en Colombia por traductor oficial 
                            colombiano que esté inscrito en el Ministerio de Relaciones Exteriores.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Si alguno de los(las) dos contrayentes es extranjero(a) y no entiende el castellano, 
                            para la ceremonia deberá asistir con un intérprete o traductor oficial el cual debe 
                            designarse en el momento de presentar la solicitud de trámite notarial de matrimonio.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Solicitar resolución donde acreditan al traductor como intérprete oficial.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Copia auténtica de la Escritura Pública de Capitulaciones ( En caso de que los 
                            contrayentes hayan realizado capitulaciones) 
                            y posteriormente deben estar inscritas en el Registro Civil de Nacimiento</b>
                        </h5>
                    </li>
                </ol>

                </TabPane>

            </TabContent>
            <h4 className="description text-justify" style={{color: "Black", fontSize:"11pt"}}>
                <b>•	Nota: los documentos provenientes del exterior deben venir apostillados. 
                Si el(la) extranjero(a) es nacido(a) en un país que esté en el convenio de la haya, 
                como: (españa, estados unidos, méxico, italia, alemania, francia, japón, holanda, 
                venezuela, argentina y otros.) De lo contrario deberá realizar la cadena de 
                legalización- http://www.cancilleria.gov.co/tramites_servicios/apostilla_legalizacion_en_linea/que_es_legal</b>
            </h4>
            <h4 className="description text-justify" style={{color: "Black", fontSize:"11pt"}}>
                <b></b>•	Cuando alguno de los(las) contrayentes este en el exterior 
                y vaya a contraer nupcias mediante poder, este deberá ser autenticado 
                en el consulado colombiano en el país donde se encuentre o ante un notario, 
                quien deberá dejar señalado el nombre de quien comparece “contrayente” y 
                reconocer el contenido del documento y luego hacer el apostille.
            </h4>
            <h4 className="description text-justify" style={{color: "Black", fontSize:"11pt"}}>
                <b></b>•	Los documentos deberán aportarse a la notaría con mínimo 10 días 
                de anticipación a la fecha de matrimonio por ambos contrayentes.
            </h4>
            <h4 className="description text-justify" style={{color: "Black"}}>
                <b>Tiempo estimado de entrega: </b>Consulte con el notario, dado que la celebración del matrimonio depende del cumplimiento de los requisitos legales.
            </h4>
            <h4 className="description text-justify" style={{color: "Black"}}>
                <b>Costo: </b>Según la tarifa notarial.
            </h4>
        </ModalBody>
        <ModalFooter>
            <Button
                color="azul"
                type="button"
                onClick={() => setModal6(false)}
                className="text-center"
            >
                <b style={{fontSize: "17px"}}>Cerrar</b>
            </Button>
        </ModalFooter>
        </div>
    </Modal>
    {/*
    .##.....##.##....##.####..#######..##....##
    .##.....##.###...##..##..##.....##.###...##
    .##.....##.####..##..##..##.....##.####..##
    .##.....##.##.##.##..##..##.....##.##.##.##
    .##.....##.##..####..##..##.....##.##..####
    .##.....##.##...###..##..##.....##.##...###
    ..#######..##....##.####..#######..##....##
    */}
    <Modal isOpen={Modal7} toggle={() => setModal7(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{backgroundColor: "White" ,borderRadius: "15px", border: "3px solid #979797"}}>
        <div className="modal-header">
            <h3> </h3>
            <button
            aria-label="Close"
            className="close"
            type="button"
            onClick={() => setModal7(false)}
            >
            <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
            </button>
        </div>
        <ModalBody>
            <h1 className="text-center title">
                <b style={{color : "#1c6e38"}}>DECLARACIÓN DE UNIÓN </b>MARITAL DE HECHO<br></br>
            </h1>
            <h5 className="description text-justify" style={{color: "Black"}}>
                <b>Es la manifestación ante juez o notario de la existencia de la unión entre
                        dos personas que. sin estar casadas ante la ley, han estado conviviendo 
                        de manera permanente y libre durante mínimo 2 años. Esta diligencia termina 
                        con la firma del notario en la escritura pública.</b>
            </h5>
            <h3 className="azul-title text-center">Requisitos:</h3>
            <h5 className="description text-justify" style={{color: "Black"}}>
            <b>La pareja de compañeros permanentes debe presentar solicitud 
                escrita dirigida al notario en la que manifieste su intención 
                de declarar la unión marital de hecho existente, para lo cual debe 
                expresar que no tiene los impedimentos legales para contraer matrimonio y anexar:</b>
            </h5>
            <ol>
                <li>
                    <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Documentos de identificación.</b>
                    </h5>
                </li>
                <li>
                    <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Copias auténticas de los registros civiles de nacimiento que no tengan más 
                        de un mes de expedidas, en las que se incluyan todas las anotaciones de estados civiles anteriores.</b>
                    </h5>
                </li>
                <li>
                    <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Datos completos (nombres, apellidos, número de documento de identidad, lugar de nacimiento, edad,
                            ocupación y domicilio). Manifestación de que actúan de común acuerdo.</b>
                    </h5>
                </li>
                <li>
                    <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Fecha desde la cual conviven juntos.</b>
                    </h5>
                </li>
                <li>
                    <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>De ser el caso, identificación de hijos comunes.</b>
                    </h5>
                </li>
                <li>
                    <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Si tienen hijos y los declarantes son el padre y la madre 
                        (hijos comunes) deben anexar las copias de los registros civiles de nacimiento de cada uno de los hijos.</b>
                    </h5>
                </li>
                <li>
                    <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Para el caso de hijos no comunes, que se encuentren bajo su patria potestad o bajo su guarda, 
                        deben presentar inventario de los bienes, si los hay, que sean de propiedad de cada hijo.El inventario lo elaborará 
                        el curador designado según la ley. Sobre este trámite, consultar al notario. </b>
                    </h5>
                </li>
            </ol>
            <h4 className="description text-justify" style={{color: "Black"}}>
                <b>Tiempo estimado de entrega: </b>Una semana aproximadamente.
            </h4>
            <h4 className="description text-justify" style={{color: "Black"}}>
                <b>Costo: </b>Sí. Según la tarifa notarial.
            </h4>
        </ModalBody>
        <ModalFooter>
            <Button
                color="azul"
                type="button"
                onClick={() => setModal7(false)}
                className="text-center"
            >
                <b style={{fontSize: "17px"}}>Cerrar</b>
            </Button>
        </ModalFooter>
        </div>
    </Modal>
    {/*
    ..######.....###....########..####.########.##.....##.##..........###.....######..####..#######..##....##.########..######.
    .##....##...##.##...##.....##..##.....##....##.....##.##.........##.##...##....##..##..##.....##.###...##.##.......##....##
    .##........##...##..##.....##..##.....##....##.....##.##........##...##..##........##..##.....##.####..##.##.......##......
    .##.......##.....##.########...##.....##....##.....##.##.......##.....##.##........##..##.....##.##.##.##.######....######.
    .##.......#########.##.........##.....##....##.....##.##.......#########.##........##..##.....##.##..####.##.............##
    .##....##.##.....##.##.........##.....##....##.....##.##.......##.....##.##....##..##..##.....##.##...###.##.......##....##
    ..######..##.....##.##........####....##.....#######..########.##.....##..######..####..#######..##....##.########..######.
    */}
    <Modal isOpen={Modal8} toggle={() => setModal8(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{backgroundColor: "White" ,borderRadius: "15px", border: "3px solid #979797"}}>
            <div className="modal-header">
                <h3>

                </h3>
                <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={() => setModal8(false)}
                >
                <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
                </button>
            </div>
            <ModalBody>
                <h1 className="text-center title">
                    <b style={{color : "#1c6e38"}}>CAPITULACIONES </b>MATRIMONIALES<br></br>
                </h1>
                <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Son los acuerdos que hacen las personas que se van a casar (por matrimonio civil o matrimonio religioso) 
                        sobre los bienes que tienen o podrán tener, para incluirlos o no en la futura sociedad conyugal que nacerá 
                        por el hecho del matrimonio. Las capitulaciones se deben hacer ante notario por escritura pública, antes de 
                        contraer matrimonio. Si se hacen después del matrimonio, no sirven. Una vez hechas, las capitulaciones 
                        no se pueden modificar. Si el matrimonio no se realiza, las capitulaciones no tienen efecto, ni pueden hacerse cumplir.</b>
                </h5>
                <h3 className="azul-title text-center">Requisitos:</h3>
                <ol>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Documentos de identificación de los interesados.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Relación detallada de los bienes (muebles e inmuebles) que las personas que se van a casar
                                aportan al matrimonio y de aquellos bienes que no se incluyen en la sociedad conyugal, 
                                con indicación de su valor y los documentos que acreditan su costo, así como una relación de las deudas de cada uno.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Recibo de pago del impuesto predial que contenga el avalúo catastral, 
                            correspondiente al año en que se celebran las capitulaciones, para determinar el valor de los inmuebles.</b>
                        </h5>
                    </li>
                </ol>
                <h4 className="description text-justify" style={{color: "Black"}}>
                    <b>Tiempo estimado de entrega: </b>Consulte con el notario, dado que la diligencia de la escritura pública depende de 
                    las características particulares de los interesados, de los bienes y del cumplimiento de los requisitos legales.
                </h4>
                <h4 className="description text-justify" style={{color: "Black"}}>
                    <b>Costo: </b>Sí. Según la tarifa notarial.
                </h4>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="azul"
                    type="button"
                    onClick={() => setModal8(false)}
                    className="text-center"
                >
                    <b style={{fontSize: "17px"}}>Cerrar</b>
                </Button>
            </ModalFooter>
        </div>
    </Modal>
    {/*
    .########..######...######..########..####.########.##.....##.########.....###.....######..####.##....##
    .##.......##....##.##....##.##.....##..##.....##....##.....##.##.....##...##.##...##....##..##..###...##
    .##.......##.......##.......##.....##..##.....##....##.....##.##.....##..##...##..##........##..####..##
    .######....######..##.......########...##.....##....##.....##.########..##.....##.##........##..##.##.##
    .##.............##.##.......##...##....##.....##....##.....##.##...##...#########.##........##..##..####
    .##.......##....##.##....##.##....##...##.....##....##.....##.##....##..##.....##.##....##..##..##...###
    .########..######...######..##.....##.####....##.....#######..##.....##.##.....##..######..####.##....##
    */}
    <Modal isOpen={Modal9} toggle={() => setModal9(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{backgroundColor: "White" ,borderRadius: "15px", border: "3px solid #979797"}}>
            <div className="modal-header">
                <h3> </h3>
                <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={() => setModal9(false)}
                >
                    <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
                </button>
            </div>
            <ModalBody>
                <h1 className="text-center title">
                    <b style={{color : "#1c6e38"}}>ESCRITURACIÓN</b><br></br>
                </h1>
                <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Es un documento que contiene la declaración de voluntad de una o varias personas, 
                        emitidas ante el notario, para ahcer un determinado contrato o un acto jurídico individual.</b>
                </h5>
                <h3 className="azul-title text-center">El proceso de elaboración de una escritura pública tiene cuatro etapas en la notaría:</h3>
                <ol>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Recepción de las declaraciones, esot es, de lo que el usuario quiere decir ante el notario.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Extensión o transcripció en texto escrito de lo dicho o declarado por el usuario, ante el notario.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Lectura, aceptación y otorgamiento del texto que ha leído o ha oido el usuario.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Autorización y firma del notario, después de la firma de los interesados y después de cumplidos todos los requisitos del contrato respectivo.</b>
                        </h5>
                    </li>
                </ol>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="azul"
                    type="button"
                    onClick={() => setModal9(false)}
                    className="text-center"
                >
                    <b style={{fontSize: "17px"}}>Cerrar</b>
                </Button>
            </ModalFooter>
        </div>
    </Modal>
  {/*
  .......########..########...#######..##.....##.########..######.....###...
  .......##.....##.##.....##.##.....##.###...###.##.......##....##...##.##..
  .......##.....##.##.....##.##.....##.####.####.##.......##........##...##.
  .......########..########..##.....##.##.###.##.######....######..##.....##
  .......##........##...##...##.....##.##.....##.##.............##.#########
  .......##........##....##..##.....##.##.....##.##.......##....##.##.....##
  .......##........##.....##..#######..##.....##.########..######..##.....##
  */}
    <Modal isOpen={Modal10} toggle={() => setModal10(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{backgroundColor: "White" ,borderRadius: "15px", border: "3px solid #979797"}}>
            <div className="modal-header">
                <h3> </h3>
                <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={() => setModal10(false)}
                >
                <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
                </button>
            </div>
            <ModalBody>
                <h1 className="text-center title">
                    <b style={{color : "#1c6e38"}}> PROMESA DE COMPRAVENTA </b>DE UN INMUEBLE<br></br>
                </h1>
                <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Es un documento privado que realizan las partes para celebraren el futuro un contrato por escritura pública. La promesa de compraventa no hace propietario del inmueble al futuro comprador. 
                        Sólo se adquiere la propiedad con la escritura pública de compra debidamente registrada en la Oficina de Registro de Instrumentos Públicos.</b>
                </h5>
                <h3 className="azul-title text-center">Requisitos:</h3>
                <ol>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>La persona debe ser mayordeedady por lo tanto está en capacidad de hacerlo.Lapersona debe ser mayordeedady por lo tanto está en capacidad de hacerlo.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>La promesa constará por escrito y debe contenerla fecha, hora y la notaría donde se firmará la escritura de compraventa.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Las personas que firman la promesa de compraventa, deben estar de acuerdo con su contenido.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Lo que se promete comprar o vender debe estar permitido por la ley.</b>
                        </h5>
                    </li>
                </ol>
                <h4 className="description text-justify" style={{color: "Black"}}>
                    <b>Tiempo estimado de entrega: </b>El mismo día, si se cumplen todos los requisitos de ley.
                </h4>
                <h4 className="description text-justify" style={{color: "Black"}}>
                    <b>Costo: </b>Sí. Según la tarifa notarial.
                </h4>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="azul"
                    type="button"
                    onClick={() => setModal10(false)}
                    className="text-center"
                >
                    <b style={{fontSize: "17px"}}>Cerrar</b>
                </Button>
            </ModalFooter>
        </div>
    </Modal>
    {/*
    ..######...#######..##.....##.########..########.....###....##.....##.########.##....##.########....###...
    .##....##.##.....##.###...###.##.....##.##.....##...##.##...##.....##.##.......###...##....##......##.##..
    .##.......##.....##.####.####.##.....##.##.....##..##...##..##.....##.##.......####..##....##.....##...##.
    .##.......##.....##.##.###.##.########..########..##.....##.##.....##.######...##.##.##....##....##.....##
    .##.......##.....##.##.....##.##........##...##...#########..##...##..##.......##..####....##....#########
    .##....##.##.....##.##.....##.##........##....##..##.....##...##.##...##.......##...###....##....##.....##
    ..######...#######..##.....##.##........##.....##.##.....##....###....########.##....##....##....##.....##
    */}
    <Modal isOpen={Modal11} toggle={() => setModal11(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{backgroundColor: "White" ,borderRadius: "15px", border: "3px solid #979797"}}>
            <div className="modal-header">
                <h3> </h3>
                <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={() => setModal11(false)}
                >
                <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
                </button>
            </div>
            <ModalBody>
                <h1 className="text-center title">
                    <b style={{color : "#1c6e38"}}>COMPRAVENTA </b>DE INMUEBLES<br></br>
                </h1>
                <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Mediante este contrato una persona se convierte en propietaria de una casa, lote sin construcción,
                            apartamento o una finca, entre otros. Consiste en traspasar a Otra persona uno de esos inmuebles
                            a cambio de un valor en dinero que el comprador le paga al vendedor. Este contrato se hace 
                            obligatoriamente por medio de escritura pública, Vendedor y comprador deben cumplir dos etapas:</b>
                </h5>
                <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>PRIMERA ETAPA EN LA NOTARÍA: </b>Hacer la escritura pública de compraventa, en la que se
                        especifiquen los linderos de la vivienda o de la finca y el precio.
                </h5>
                <h3 className="azul-title text-center">Requisitos:</h3>
                <ol>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Documentos de identificación de los interesados.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>El vendedor debe ser el dueño del inmueble y mayor de edad. El comprador también debe ser mayor de edad.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Si una de las partes es persona menor de edad, deberá comprar o vender por representante legal
                                (padres en ejercicio de la patria potestad, entre otros).</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Certificados de paz y salvo de impuesto predial que contenga el avalúo catastral y de contribución de valorización, 
                            si aplica en el municipio. Si el inmueble está sometido a propiedad horizontal, se necesita también el certificado 
                            de paz y salvo por el pago de la administración.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Si el vendedor o comprador es una persona jurídica, como una sociedad o un banco, 
                            debe presentarse el documento que acredite su existencia y representación legal.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Se recomienda que el vendedor, como dueño de la vivienda, presente al notario copia de la 
                            escritura pública o título de adquisición, junto con el certificado de libertad y tradición 
                            más reciente que le da la Oficina de Registro de Instrumentos Públicos, para poderverificar 
                            la historia del inmueble y los linderos.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Cuando el apartamento esté en el régimen de propiedad horizontal, se debe presentar 
                            copia de la parte del reglamento que se refiere a la propiedad que se vende.</b>
                        </h5>
                    </li>
                </ol>
                <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>SEGUNDA ETAPA EN LA OFICINA DE REGISTRO DE INSTRUMENTOS PUBLICOS: </b>Una vez terminada la primera etapa, o sea, la de la
                        escritura pública, el notario entrega copia auténtica a los interesados para llevarla a esta oficina.
                </h5>
                <h4 className="description text-justify" style={{color: "Black"}}>
                    <b>Tiempo estimado de entrega: </b>Es de mínimo ocho días hábiles, si la escritura es firmada en el mismo momento por vendedor y comprador,
                        para que el notario revise la legalidad del contrato. Si no firman al tiempo, puede superar este plazo. Consulte al notario.
                </h4>
                <h4 className="description text-justify" style={{color: "Black"}}>
                    <b>Costo: </b>Sí. Según la tarifa notarial.
                </h4>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="azul"
                    type="button"
                    onClick={() => setModal11(false)}
                    className="text-center"
                >
                    <b style={{fontSize: "17px"}}>Cerrar</b>
                </Button>
            </ModalFooter>
        </div>
    </Modal>
    {/*
    .########..########.########..##.....##.##.....##.########....###...
    .##.....##.##.......##.....##.###...###.##.....##....##......##.##..
    .##.....##.##.......##.....##.####.####.##.....##....##.....##...##.
    .########..######...########..##.###.##.##.....##....##....##.....##
    .##........##.......##...##...##.....##.##.....##....##....#########
    .##........##.......##....##..##.....##.##.....##....##....##.....##
    .##........########.##.....##.##.....##..#######.....##....##.....##
    */}
    <Modal isOpen={Modal12} toggle={() => setModal12(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{backgroundColor: "White" ,borderRadius: "15px", border: "3px solid #979797"}}>
            <div className="modal-header">
                <h3> </h3>
                <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={() => setModal12(false)}
                >
                <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
                </button>
            </div>
            <ModalBody>
                <h1 className="text-center title">
                    <b style={{color : "#1c6e38"}}>PERMUTA </b>DE INMUEBLES<br></br>
                </h1>
                <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Es uno de los contratos para que una persona se convierta en propietaria de una vivienda, 
                        lote sin construcción o de una finca, entre otros bienes. Consiste, por ejemplo, en el 
                        intercambio de una vivienda 0 de una finca por casas o cosas y dinero. Para que sea una 
                        permuta, el intercambio debe hacerse por un valor mayor en cosas que en dinero. Si el valores
                            mayor en dinero, el contrato será de compraventa.<br></br>
                        Para realizar este contrato que se hace por medio de escritura pública, los interesados deben cumplir dos etapas:</b>
                </h5>
                <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>PRIMERA ETAPA EN LA NOTARÍA: </b>Hacer la escritura pública de permuta, en la que se 
                    especifiquen los linderos de los bienes que se intercambian y el precio de los mismos.
                </h5>
                <h3 className="azul-title text-center">Requisitos:</h3>
                <ol>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Documentos de identificación de las personas que hacen el contrato.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Estas personas deben ser mayores de edad y por lo tanto están en capacidad de hacerlo.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Certificados de paz y salvo por impuesto predial que se paga en la tesorería municipal y 
                            que contiene el avalúo catastral. Además, el paz y salvo por contribución de valorización, 
                            si aplica en el municipio. Si el inmueble está sometido a propiedad horizontal, se necesita 
                            también el certificado de paz y salvo por el pago de cuotas de la administración.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Si alguno de quienes hacen el contrato, es persona jurídica, como una sociedad o 
                            un banco, debe presentarse el documento que acredite su existencia y representación legal.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Se recomienda que se lleve al notario copia de la escritura pública o título de adquisición,
                                junto con el certificado de libertad y tradición más reciente que le ha dado la Oficina 
                                de Registro de Instrumentos Públicos.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Se sugiere que se presente copia de la parte del reglamento que se refiere a las propiedades que se permutan.</b>
                        </h5>
                    </li>
                </ol>
                <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>SEGUNDA ETAPA EN LA OFICINA DE REGISTRO DE INSTRUMENTOS PUBLICOS: </b>Una vez terminada la primera etapa, o sea, 
                    la de la escritura pública, el notario entrega copia auténtica a los interesados para llevarla a esta oficina.
                    <br></br>
                    Para ser propietario de bienes inmuebles se requiere la escritura pública debidamente registrada donde consten la compraventa o la permuta. No olvide este requisito.
                </h5>
                <h4 className="description text-justify" style={{color: "Black"}}>
                    <b>Tiempo estimado de entrega: </b> Si firman al tiempo los interesados, es mínimo ocho días hábiles, mientras el notario revisa la legalidad del 
                    contrato. firman al tiempo, puede superareste plazo.
                </h4>
                <h4 className="description text-justify" style={{color: "Black"}}>
                    <b>Costo: </b>Sí. Según la tarifa notarial.
                </h4>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="azul"
                    type="button"
                    onClick={() => setModal12(false)}
                    className="text-center"
                >
                    <b style={{fontSize: "17px"}}>Cerrar</b>
                </Button>
            </ModalFooter>
            </div>
    </Modal>
    {/*
    ....###....##.....##.########.########.##....##.########.####..######.....###.....######..####..#######..##....##
    ...##.##...##.....##....##....##.......###...##....##.....##..##....##...##.##...##....##..##..##.....##.###...##
    ..##...##..##.....##....##....##.......####..##....##.....##..##........##...##..##........##..##.....##.####..##
    .##.....##.##.....##....##....######...##.##.##....##.....##..##.......##.....##.##........##..##.....##.##.##.##
    .#########.##.....##....##....##.......##..####....##.....##..##.......#########.##........##..##.....##.##..####
    .##.....##.##.....##....##....##.......##...###....##.....##..##....##.##.....##.##....##..##..##.....##.##...###
    .##.....##..#######.....##....########.##....##....##....####..######..##.....##..######..####..#######..##....##
    */}
    <Modal isOpen={Modal13} toggle={() => setModal13(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{backgroundColor: "White" ,borderRadius: "15px", border: "3px solid #979797"}}>
            <div className="modal-header">
                <h3> </h3>
                <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={() => setModal13(false)}
                >
                <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
                </button>
            </div>
            <ModalBody>
                <h1 className="text-center title">
                    <b style={{color : "#1c6e38"}}>AUTENTICACIÓN</b><br></br>
                </h1>
                <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Es cuando el notario da testimonio escrito de que las firmas que aparecen
                            en un documento privado fueron puestas en su presencia, estableciendo
                            la identidad de los firmantes y dando fe del día en que el hecho ocurrió.</b>
                </h5>
                <h3 className="azul-title text-center">Requisitos:</h3>
                <ol>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Asistir personalmente a la notaría. Puede hacerse también adomicilio.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Llevarla cédula de ciudadanía.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Llevar el documento que se va a autenticar.</b>
                        </h5>
                    </li>
                </ol>
                <h4 className="description text-justify" style={{color: "Black"}}>
                    <b>Tiempo estimado de entrega: </b>El mismo día.
                </h4>
                <h4 className="description text-justify" style={{color: "Black"}}>
                    <b>Costo: </b>Sí. Según la tarifa notarial.
                </h4>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="azul"
                    type="button"
                    onClick={() => setModal13(false)}
                    className="text-center"
                >
                    <b style={{fontSize: "17px"}}>Cerrar</b>
                </Button>
            </ModalFooter>
        </div>
    </Modal>
    {/*
    .########..########..######...#######..##....##..#######...######..####.##.....##.####.########.##....##.########..#######.
    .##.....##.##.......##....##.##.....##.###...##.##.....##.##....##..##..###...###..##..##.......###...##....##....##.....##
    .##.....##.##.......##.......##.....##.####..##.##.....##.##........##..####.####..##..##.......####..##....##....##.....##
    .########..######...##.......##.....##.##.##.##.##.....##.##........##..##.###.##..##..######...##.##.##....##....##.....##
    .##...##...##.......##.......##.....##.##..####.##.....##.##........##..##.....##..##..##.......##..####....##....##.....##
    .##....##..##.......##....##.##.....##.##...###.##.....##.##....##..##..##.....##..##..##.......##...###....##....##.....##
    .##.....##.########..######...#######..##....##..#######...######..####.##.....##.####.########.##....##....##.....#######.
    */}
    <Modal isOpen={Modal14} toggle={() => setModal14(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{backgroundColor: "White" ,borderRadius: "15px", border: "3px solid #979797"}}>
            <div className="modal-header">
                <h3> </h3>
                <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={() => setModal14(false)}
                >
                <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
                </button>
            </div>
            <ModalBody>
                <h1 className="text-center title">
                    <b style={{color : "#1c6e38"}}>RECONOCIMIENTO </b>DE FIRMA Y CONTENIDO<br></br>
                </h1>
                <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Quienes hayan firmado un documento privado podrán acudir ante el notario
                            para declarar que las firmas son suyas y que el contenido del documento 
                            es cierto. La diligencia concluye con las firmas de los declarantes y del notario.
                            De la veracidad de lo declarado responden los declarantes.</b>
                </h5>
                <h3 className="azul-title text-center">Requisitos:</h3>
                <ol>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Asistir personalmente a la notaría.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Llevarla cédula de ciudadanía.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Llevar el documento que se va a reconocer.</b>
                        </h5>
                    </li>
                </ol>
                <h4 className="description text-justify" style={{color: "Black"}}>
                    <b>Tiempo estimado de entrega: </b>El mismo día.
                </h4>
                <h4 className="description text-justify" style={{color: "Black"}}>
                    <b>Costo: </b>Sí. Según la tarifa notarial.
                </h4>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="azul"
                    type="button"
                    onClick={() => setModal14(false)}
                    className="text-center"
                >
                    <b style={{fontSize: "17px"}}>Cerrar</b>
                </Button>
            </ModalFooter>
        </div>
    </Modal>
    {/*
    .########.####.########..##.....##....###...
    .##........##..##.....##.###...###...##.##..
    .##........##..##.....##.####.####..##...##.
    .######....##..########..##.###.##.##.....##
    .##........##..##...##...##.....##.#########
    .##........##..##....##..##.....##.##.....##
    .##.......####.##.....##.##.....##.##.....##
    */}
    <Modal isOpen={Modal15} toggle={() => setModal15(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{backgroundColor: "White" ,borderRadius: "15px", border: "3px solid #979797"}}>
            <div className="modal-header">
                <h3> </h3>
                <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={() => setModal15(false)}
                >
                <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
                </button>
            </div>
            <ModalBody>
                <h1 className="text-center title">
                    <b style={{color : "#1c6e38"}}>FIRMA A RUEGO - </b>PERSONAS QUE NO SABEN O NO PUEDEN FIRMAR<br></br>
                </h1>
                <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Cuando se trate de personas que no sepan o no puedan firmar, en la diligencia 
                        se leerá en voz alta el documento que será firmado por un testigo presentado 
                        por el interesado. De esta diligencia, denominada 'firma a ruego", el notario 
                        dejará constancia en el mismo documento. Para las personas con discapacidad visual 
                        o que no sepan leer y escribir, el notario leerá en voz alta el documento. Para las 
                        personas sordas, ellos mismos leerán el documento y expresarán su aprobación.</b>
                </h5>
                <h3 className="azul-title text-center">Requisitos:</h3>
                <ol>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Asistir personalmente a la notaría. Excepcionalmente, puede hacerse fuera del despacho notarial.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Llevar la cédula de ciudadanía.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Llevar el testigo cuando así se requiera.</b>
                        </h5>
                    </li>
                </ol>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="azul"
                    type="button"
                    onClick={() => setModal15(false)}
                    className="text-center"
                >
                    <b style={{fontSize: "17px"}}>Cerrar</b>
                </Button>
            </ModalFooter>
        </div>
    </Modal>
     {/*
    .########..########..######..##..........###....########.....###.....######..####..#######..##....##.########..######.
    .##.....##.##.......##....##.##.........##.##...##.....##...##.##...##....##..##..##.....##.###...##.##.......##....##
    .##.....##.##.......##.......##........##...##..##.....##..##...##..##........##..##.....##.####..##.##.......##......
    .##.....##.######...##.......##.......##.....##.########..##.....##.##........##..##.....##.##.##.##.######....######.
    .##.....##.##.......##.......##.......#########.##...##...#########.##........##..##.....##.##..####.##.............##
    .##.....##.##.......##....##.##.......##.....##.##....##..##.....##.##....##..##..##.....##.##...###.##.......##....##
    .########..########..######..########.##.....##.##.....##.##.....##..######..####..#######..##....##.########..######.
    */}
    <Modal isOpen={Modal16} toggle={() => setModal16(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{backgroundColor: "White" ,borderRadius: "15px", border: "3px solid #979797"}}>
            <div className="modal-header">
                <h3> </h3>
                <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={() => setModal16(false)}
                >
                <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
                </button>
            </div>
            <ModalBody>
                <h1 className="text-center title">
                    <b style={{color : "#1c6e38"}}>EXTRA PROCESO O </b>DECLARACIONES BAJO GRAVEDAD DE JURAMENTO<br></br>
                </h1>
                <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Son declaraciones verbales o escritas presentadas ante el notario en las que 
                        se asegura bajo juramento la existencia de determinados hechos. 
                        La declaración verbal se transcribe en la notaría y la escrita 
                        la puede llevar el interesado.</b>
                </h5>
                <h3 className="azul-title text-center">Requisitos:</h3>
                <ol>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Asistir personalmente a la notaría.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Llevarla cédula de ciudadanía.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Llevarla declaración escrita para revisión del notario o darla versión verbalmente.</b>
                        </h5>
                    </li>
                </ol>
                <h4 className="description text-justify" style={{color: "Black"}}>
                    <b>Tiempo estimado de entrega: </b>El mismo día.
                </h4>
                <h4 className="description text-justify" style={{color: "Black"}}>
                    <b>Costo: </b>Si. Según tarifa notarial. Las madres que acrediten su condición de cabeza de familia no tienen que pagar.
                </h4>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="azul"
                    type="button"
                    onClick={() => setModal16(false)}
                    className="text-center"
                >
                    <b style={{fontSize: "17px"}}>Cerrar</b>
                </Button>
            </ModalFooter>
        </div>
    </Modal>
    {/*
    .########...#######..########..########.########.
    .##.....##.##.....##.##.....##.##.......##.....##
    .##.....##.##.....##.##.....##.##.......##.....##
    .########..##.....##.##.....##.######...########.
    .##........##.....##.##.....##.##.......##...##..
    .##........##.....##.##.....##.##.......##....##.
    .##.........#######..########..########.##.....##
    */}
    <Modal isOpen={Modal17} toggle={() => setModal17(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{backgroundColor: "White" ,borderRadius: "15px", border: "3px solid #979797"}}>
            <div className="modal-header">
                <h3> </h3>
                <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={() => setModal17(false)}
                >
                <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
                </button>
            </div>
            <ModalBody>
                <h1 className="text-center title">
                    <b style={{color : "#1c6e38"}}>PODER</b><br></br>
                </h1>
                <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Es el acto mediante el cual una persona autoriza a otra para que la represente en la realización 
                        de un contrato o acto jurídico. En cada poder se debe especificar el propósito para el cual 
                        se otorga, es decir, debe contener la relación precisa de las facultades que se dan y para 
                        qué se dan a la persona que recibe el poder (apoderada). El poder puede constar en documento
                            privado autenticado en su firma y reconocido en su contenido o en escritura pública.</b>
                </h5>
                <h3 className="azul-title text-center">Requisitos:</h3>
                <ol>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>La persona que va a darel poderdebe asistira la notaría.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Llevarla cédula de ciudadanía.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Llevar el documento escrito para que el notario lo autentique y reconozca.</b>
                        </h5>
                    </li>
                </ol>
                <h4 className="description text-justify" style={{color: "Black"}}>
                    <b>Tiempo estimado de entrega: </b>El mismo día.
                </h4>
                <h4 className="description text-justify" style={{color: "Black"}}>
                    <b>Costo: </b>Sí. Según la tarifa notarial.
                </h4>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="azul"
                    type="button"
                    onClick={() => setModal17(false)}
                    className="text-center"
                >
                    <b style={{fontSize: "17px"}}>Cerrar</b>
                </Button>
            </ModalFooter>
        </div>
    </Modal>
    {/*
    ..######..##.....##..######..########..######..####..#######..##....##.########..######.
    .##....##.##.....##.##....##.##.......##....##..##..##.....##.###...##.##.......##....##
    .##.......##.....##.##.......##.......##........##..##.....##.####..##.##.......##......
    ..######..##.....##.##.......######....######...##..##.....##.##.##.##.######....######.
    .......##.##.....##.##.......##.............##..##..##.....##.##..####.##.............##
    .##....##.##.....##.##....##.##.......##....##..##..##.....##.##...###.##.......##....##
    ..######...#######...######..########..######..####..#######..##....##.########..######.
    */}
    <Modal isOpen={Modal18} toggle={() => setModal18(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{backgroundColor: "White" ,borderRadius: "15px", border: "3px solid #979797"}}>
            <div className="modal-header">
                <h3> </h3>
                <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={() => setModal18(false)}
                >
                <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
                </button>
            </div>
            <ModalBody>
                <h1 className="text-center title">
                    <b style={{color : "#1c6e38"}}>SUCESIÓN DE BIENES </b>POR CAUSA DE MUERTE<br></br>
                </h1>
                <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Es la que se hace para repartir los bienes del difunto, restando de 
                        ellos las deudas que tenía. Esta repartición se hace, una vez pagados 
                        los impuestos respectivos, entre los herederos del difunto, los acreedores 
                        y las demás personas que tengan interés en la misma según la ley. 
                        Este acto tiene un trámite especial, incluyendo edictos para azulrmar 
                        al público sobre el inicio de la sucesión. El acto de la sucesión termina 
                        con la firma del notario en la escritura pública.</b>
                </h5>
                <h5 className="description text-justify" style={{color: "Black"}}>
                <b>Todos los interesados deben estar de acuerdo desde el comienzo hasta 
                    el final de la sucesión. En el caso de surgir un desacuerdo, la sucesión pasa a juzgado.</b>
                </h5>
                <h3 className="azul-title text-center">Requisitos:</h3>
                <ol>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Tanto el viudo o viuda, como todos los hijos y demás herederos e interesados, tienen que dar 
                            poder especial a un abogado para que adelante la diligencia. Este poder puede hacerse por 
                            escritura pública o por documento privado, en cuyo caso necesita de una autenticación especial
                            llamada "reconocimiento de firma y contenido" y debe incluir la afirmación bajo juramento de
                                la no existencia de otros herederos o interesados.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Solicitud escrita por el abogado de la sucesión, junto con el inventario de bienes, 
                            la estimación de su valor y la repartición de los bienes entre los solicitantes.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Si el difunto dejó bienes inmuebles se deben presentar los certificados de paz y salvo
                                por impuesto predial. Si el inmueble es un apartamento o un local en un edificio, 
                                se necesita también el certificado de paz y salvo por el pago de la administración.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Si en el inventario se incluyen deudas, debe presentarse el documento que lo pruebe.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Copia auténtica del registro civil de defunción de la persona que falleció.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Si el difunto fue casado se requiere presentar copia auténtica del registro civil de matrimonio, 
                            para liquidar primero la sociedad conyugal y entregarle al viudo o viuda lo que le corresponde. 
                            En caso de unión marital de hecho, se debe presentar la declaración respectiva.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Si hubo hijos y todos están de acuerdo en llevar la sucesión ante notario, deben presentar sus 
                            registros civiles de nacimiento, para probar su calidad de herederos. Todos heredan por partes 
                            iguales si no hay testamento.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Si el difunto hizo testamento abierto, debe presentarse copia auténtica de la escritura pública. 
                            Si hizo testamento cerrado o secreto, debe presentar copia auténtica de la escritura pública 
                            donde consta la diligencia de apertura.</b>
                        </h5>
                    </li>
                </ol>
                <h4 className="description text-justify" style={{color: "Black"}}>
                    <b>Tiempo estimado de entrega: </b>Consulte con el notario, dado que el trámite de la sucesión tiene 
                    características particulares según os solicitantes, los bienes que se reparten y lo ordenado en el 
                    testamento, si lo hubo.
                </h4>
                <h4 className="description text-justify" style={{color: "Black"}}>
                    <b>Costo: </b>Sí. Según la tarifa notarial.
                </h4>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="azul"
                    type="button"
                    onClick={() => setModal18(false)}
                    className="text-center"
                >
                    <b style={{fontSize: "17px"}}>Cerrar</b>
                </Button>
            </ModalFooter>
        </div>
    </Modal>
    {/*
    .########.########..######..########....###....##.....##.########.##....##.########..#######.
    ....##....##.......##....##....##......##.##...###...###.##.......###...##....##....##.....##
    ....##....##.......##..........##.....##...##..####.####.##.......####..##....##....##.....##
    ....##....######....######.....##....##.....##.##.###.##.######...##.##.##....##....##.....##
    ....##....##.............##....##....#########.##.....##.##.......##..####....##....##.....##
    ....##....##.......##....##....##....##.....##.##.....##.##.......##...###....##....##.....##
    ....##....########..######.....##....##.....##.##.....##.########.##....##....##.....#######.
    */}
    <Modal isOpen={Modal19} toggle={() => setModal19(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{backgroundColor: "White" ,borderRadius: "15px", border: "3px solid #979797"}}>
            <div className="modal-header">
                <h3> </h3>
                <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={() => setModal19(false)}
                >
                <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
                </button>
            </div>
            <ModalBody>
                <h1 className="text-center title">
                    <b style={{color : "#1c6e38"}}>TESTAMENTO</b><br></br>
                </h1>
                <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Es un acto por el cual una persona dispone de todos o de una parte de sus bienes, 
                        para que así sea tenido en cuenta después de su muerte en la sucesión. La distribución 
                        de los bienes en el testamento debe hacerse de acuerdo con la ley. Mientras viva la persona,
                        puede cambiar el testamento.</b>
                </h5>
                <h5 className="description text-justify" style={{color: "Black"}}>
                <b>En el testamento la persona también puede reconocer un hijo y ese reconocimiento no puede cambiarse.</b>
                </h5>
                <h5 className="description text-justify" style={{color: "Black"}}>
                <b>Las dos clases de testamento más frecuentes son abierto y cerrado o secreto y se hace por escritura pública.</b>
                </h5>
                <h3 className="azul-title text-center">REQUISITOS PARA EL TESTAMENTO ABIERTO:</h3>
                <ol>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>La persona que va a repartir sus bienes debe ser mayor de edad y por lo tanto,
                                está en capacidad para hacerlo. El notario verificará esta condición.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Documento de identificación de la persona que va a hacer el testamento.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Tres testigos y sus documentos de identificación.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Se sugiere que la persona que va a repartir sus bienes deje por escrito sus disposiciones,
                            a partir de las cuales el notario realizará la escritura pública.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>El acto termina con la firma de la persona que hace el testamento, la de los tres testigos y la del notario.</b>
                        </h5>
                    </li>
                </ol>
                <h3 className="azul-title text-center">REQUISITOS PARA EL TESTAMENTO CERRADO:</h3>
                <ol>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>La persona que va a repartir sus bienes debe ser mayor de edad y por lo tanto 
                            está en capacidad para hacerlo. El notario verificará esta condición.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Documento de identificación de la persona que hace el testamento.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Cinco testigos y sus documentos de identificación.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Sobre cerrado presentado directamente por la persona que hace el testamento,
                            con la declaración escrita de que contiene su última voluntad. El sobre debe 
                            estar marcado con la palabra "testamento".</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>El acto termina con la firma de la persona que hace el testamento, 
                            las de los cinco testigos y la del notario. El notario guardará el testamento.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Cuando muera la persona que hizo el testamento cerrado, los interesados deben solicitaral notario su 
                            apertura, presentando: Documentos de identificación. Solicitud escrita y prueba de su relación 
                            con la persona que hizo el testamento cerrado. Si el interesado es hijo del fallecido, debe presentar 
                            copia auténtica del registro civil de nacimiento. Registro civil de defunción de la persona que hizo 
                            el testamento cerrado. Copia auténtica de la escritura pública que se realizó a partir de la entrega
                                del sobre cerrado al notario.</b>
                        </h5>
                    </li>
                </ol>
                <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Los testigos, tanto para el testamento abierto como para el testamento cerrado, 
                        tienen que cumplir ciertos requisitos de la ley, entre otros. No pueden ser 
                        menores de 1 5 años, empleados de la notaría, hermanos, tíos o primos de la persona 
                        que hizo el testamento o del notario. Consulte al notario para ampliar esta azulrmación y 
                        evitar que el testamento sea inválido..</b>
                </h5>
                <h4 className="description text-justify" style={{color: "Black"}}>
                    <b>Tiempo estimado de entrega: </b> Consulte con el notario, dado que la celebración de este 
                    acto depende de la revisión que él hace sobre la legalidad del testamento y los testigos.
                </h4>
                <h4 className="description text-justify" style={{color: "Black"}}>
                    <b>Costo: </b>Sí. Según la tarifa notarial.
                </h4>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="azul"
                    type="button"
                    onClick={() => setModal19(false)}
                    className="text-center"
                >
                    <b style={{fontSize: "17px"}}>Cerrar</b>
                </Button>
            </ModalFooter>
        </div>
    </Modal>
    {/*
    .########...#######..##....##....###.....######..####..#######..##....##
    .##.....##.##.....##.###...##...##.##...##....##..##..##.....##.###...##
    .##.....##.##.....##.####..##..##...##..##........##..##.....##.####..##
    .##.....##.##.....##.##.##.##.##.....##.##........##..##.....##.##.##.##
    .##.....##.##.....##.##..####.#########.##........##..##.....##.##..####
    .##.....##.##.....##.##...###.##.....##.##....##..##..##.....##.##...###
    .########...#######..##....##.##.....##..######..####..#######..##....##
    */}
    <Modal isOpen={Modal20} toggle={() => setModal20(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{backgroundColor: "White" ,borderRadius: "15px", border: "3px solid #979797"}}>
            <div className="modal-header">
                <h3> </h3>
                <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={() => setModal20(false)}
                >
                <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
                </button>
            </div>
            <ModalBody>
                <h1 className="text-center title">
                    <b style={{color : "#1c6e38"}}>DONACIÓN</b><br></br>
                </h1>
                <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Es uno de los contratos cuyo fin es que una persona se convierta en dueña de una vivienda, 
                        lote, finca, mueble o de una suma de dinero. cuando otra persona le regala una propiedad 
                        0 parte de ella, y el que la recibe la acepta.</b>
                </h5>
                <h5 className="description text-justify" style={{color: "Black"}}>
                <b>Solo se hace por escritura pública, cuando se recibe un inmueble o parte de él 
                    o cuando lo que se recibe vale más de 50 salarios mínimos mensuales.</b>
                </h5>
                <h5 className="description text-justify" style={{color: "Black"}}>
                <b>Cuando se va a hacer una donación que valga más de 50 salarios mínimos mensuales, 
                    hay que cumplir primero un requisito que se llama "insinuación de donación" , 
                    el cual se realiza en escritura pública en una notaría y en esa misma escritura 
                    se puede realizar la donación.</b>
                </h5>
                <h3 className="azul-title text-center">Requisitos:</h3>
                <ol>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>El que va a donar o regalar debe ser mayor de edad y por lo tanto está en capacidad de hacerlo. 
                            Si se trata de una donación a un menor, lo representa el padre o la madre, ambos o quien tiene la guarda.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Documentos de identificación de los interesados.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Solicitud por escrito de quien hace la donación y de quien la recibe, ante el notario de la ciudad 
                            o municipio en donde vive el donante, con certificado del valor comercial de la propiedad que 
                            se va a donar, si es un inmueble. Para más azulrmación consulte al notario.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Título de propiedad del bien que se va a donar, si es un inmueble.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Certificados de paz y salvo por impuesto predial que contenga el avalúo catastral 
                            y por contribución de valorización, si aplica en el municipio. Si es un apartamento 
                            y forma parte de una propiedad horizontal, se necesita también el certificado de paz 
                            y salvo por el pago de la administración.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Si alguno de los interesados es persona jurídica. como una sociedad, consulte al notario.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>La persona que hace la donación debe demostrar que queda con recursos 
                            suficientes para vivir y atender sus obligaciones.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Se recomienda llevar al notario copia de la escritura pública o título de adquisición
                                del inmueble que se va a regalar junto con el certificado de libertad y tradición 
                                más reciente que le ha dado la Oficina de Registro de Instrumentos Públicos.</b>
                        </h5>
                    </li>
                </ol>
                <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Una vez cumplidos los requisitos anteriores y se tenga la escritura pública, 
                        el notario entrega copia auténtica a los interesados para llevarla a la Oficina 
                        de Registro de Instrumentos Públicos.</b>
                </h5>
                <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>REQUISITOS PARA DONACIÓN DE BIENES QUE VALGAN MÁS DE 50 SALARIOS MÍNIMOS MENSUALES: </b>Consulte al notario.
                </h5>
                <h4 className="description text-justify" style={{color: "Black"}}>
                    <b>Tiempo estimado de entrega: </b>Depende del cumplimiento de los requisitos. Consulte al notario.
                </h4>
                <h4 className="description text-justify" style={{color: "Black"}}>
                    <b>Costo: </b>Sí. Según la tarifa notarial.
                </h4>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="azul"
                    type="button"
                    onClick={() => setModal20(false)}
                    className="text-center"
                >
                    <b style={{fontSize: "17px"}}>Cerrar</b>
                </Button>
            </ModalFooter>
        </div>
    </Modal>
    {/*
    ..######...#######..##....##..######..########.####.########.##.....##..######..####..#######..##....##
    .##....##.##.....##.###...##.##....##....##.....##.....##....##.....##.##....##..##..##.....##.###...##
    .##.......##.....##.####..##.##..........##.....##.....##....##.....##.##........##..##.....##.####..##
    .##.......##.....##.##.##.##..######.....##.....##.....##....##.....##.##........##..##.....##.##.##.##
    .##.......##.....##.##..####.......##....##.....##.....##....##.....##.##........##..##.....##.##..####
    .##....##.##.....##.##...###.##....##....##.....##.....##....##.....##.##....##..##..##.....##.##...###
    ..######...#######..##....##..######.....##....####....##.....#######...######..####..#######..##....##
    */}
    <Modal isOpen={Modal21} toggle={() => setModal21(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{backgroundColor: "White" ,borderRadius: "15px", border: "3px solid #979797"}}>
            <div className="modal-header">
                <h3> </h3>
                <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={() => setModal21(false)}
                >
                <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
                </button>
            </div>
            <ModalBody>
                <h1 className="text-center title">
                    <b style={{color : "#1c6e38"}}>CONSTITUCIÓN </b>DE HIPOTECA<br></br>
                </h1>
                <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Hay hipoteca cuando una persona, un banco o una entidad financiera (llamada acreedora) 
                        le presta a otra persona una suma de dinero y esta última (llamada deudora) asegura el 
                        pago, garantizándolo con un inmueble de su propiedad. Si el deudor incumple con los 
                        pagos (capital e intereses), el inmueble podrá ser embargado y rematado por un juez 
                        para pagarle a quien prestó el dinero. La hipoteca siempre se hace por escritura pública.</b>
                </h5>
                <h5 className="description text-justify" style={{color: "Black"}}>
                <b>Para hacer la hipoteca los interesados deben cumplir dos etapas:</b>
                </h5>
                <h5 className="description text-justify" style={{color: "Black"}}>
                <b>PRIMERA ETAPA EN LA NOTARÍA: </b>Firmar (otorgar) la escritura pública de hipoteca, en la que aparece 
                    la descripción del inmueble que se da en garantía y la manifestación de que ese inmueble 
                    queda hipotecado a favor del acreedor.
                </h5>
                <h3 className="azul-title text-center">Requisitos:</h3>
                <ol>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Los interesados deben ser mayores de edad y por lo tanto, están en capacidad de hacerlo.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Documentos de identificación de los interesados. Si alguno de los interesados es persona jurídica, 
                            debe presentarse el documento que acredite su existencia y representación legal.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Certificados de paz y salvo del impuesto predial según el valor catastral y 
                            de la contribución de valorización del inmueble que se hipoteca, si aplica en el municipio.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Certificado de libertad y tradición y copia de la escritura pública 
                            que demuestre que quien hipoteca es el dueño del bien.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Se puede dar un poder para hacer una hipoteca. Consulte al notario.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Casi siempre, la hipoteca se hace en la misma escritura de compraventa, pero puede hacerse por separado.</b>
                        </h5>
                    </li>
                </ol>
                <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>SEGUNDA ETAPA EN LA OFICINA DE REGISTRO DE INSTRUMENTOS PUBLICOS: </b>Una vez terminada la primera etapa, o sea, la de la escritura pública, 
                    el notario entrega copia auténtica a los interesados para llevarla a esa oficina, dentro de los noventa días hábiles siguientes a la 
                    fecha de la escritura. Olvidar este plazo, significa volver a iniciar el trámite de la hipoteca.
                </h5>
                <h4 className="description text-justify" style={{color: "Black"}}>
                    <b>Tiempo estimado de entrega: </b>Si firman al tiempo los interesados, es de mínimo ocho días hábiles mientras el notario revisa la legalidad 
                    del contrato. Si no firman al tiempo, puede superar este plazo.
                </h4>
                <h4 className="description text-justify" style={{color: "Black"}}>
                    <b>Costo: </b>Sí. Según la tarifa notarial.
                </h4>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="azul"
                    type="button"
                    onClick={() => setModal21(false)}
                    className="text-center"
                >
                    <b style={{fontSize: "17px"}}>Cerrar</b>
                </Button>
            </ModalFooter>
        </div>
    </Modal>
    {/*
    ..######.....###....##....##..######..########.##..........###.....######..####..#######..##....##....##.....##.####.########...#######..########.########..######.....###...
    .##....##...##.##...###...##.##....##.##.......##.........##.##...##....##..##..##.....##.###...##....##.....##..##..##.....##.##.....##....##....##.......##....##...##.##..
    .##........##...##..####..##.##.......##.......##........##...##..##........##..##.....##.####..##....##.....##..##..##.....##.##.....##....##....##.......##........##...##.
    .##.......##.....##.##.##.##.##.......######...##.......##.....##.##........##..##.....##.##.##.##....#########..##..########..##.....##....##....######...##.......##.....##
    .##.......#########.##..####.##.......##.......##.......#########.##........##..##.....##.##..####....##.....##..##..##........##.....##....##....##.......##.......#########
    .##....##.##.....##.##...###.##....##.##.......##.......##.....##.##....##..##..##.....##.##...###....##.....##..##..##........##.....##....##....##.......##....##.##.....##
    ..######..##.....##.##....##..######..########.########.##.....##..######..####..#######..##....##....##.....##.####.##.........#######.....##....########..######..##.....##
    */}
    <Modal isOpen={Modal22} toggle={() => setModal22(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{backgroundColor: "White" ,borderRadius: "15px", border: "3px solid #979797"}}>
            <div className="modal-header">
                <h3> </h3>
                <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={() => setModal22(false)}
                >
                <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
                </button>
            </div>
            <ModalBody>
                <h1 className="text-center title">
                    <b style={{color : "#1c6e38"}}>CANCELACIÓN DE </b>HIPOTECA<br></br>
                </h1>
                <h5 className="description text-justify" style={{color: "Black"}}>
                <b>Para cancelar una hipoteca, el dueño del bien hipotecado debe pagar la totalidad de la deuda al acreedor. 
                    Una vez ocurrido el pago, quien prestó el dinero (el acreedor) debe ir a la notaria para declarar que 
                    la deuda está pagada y que por ello cancela la hipoteca. Este acto de cancelación se hace también en
                        escritura pública. El notario entrega un certificado que debe llevar el dueño del inmueble a la Oficina 
                        de Registro para que su bien quede sin hipoteca.</b>
                </h5>
                <h4 className="description text-justify" style={{color: "Black"}}>
                    <b>Tiempo estimado de entrega: </b>Si firman al tiempo los interesados, es de mínimo ocho días hábiles 
                    mientras el notario revisa la legalidad del contrato. Si no firman al tiempo, puede superar este plazo.
                </h4>
                <h4 className="description text-justify" style={{color: "Black"}}>
                    <b>Costo: </b>Sí. Según la tarifa notarial.
                </h4>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="azul"
                    type="button"
                    onClick={() => setModal22(false)}
                    className="text-center"
                >
                    <b style={{fontSize: "17px"}}>Cerrar</b>
                </Button>
            </ModalFooter>
        </div>
    </Modal>
     {/*
    .##.....##.####.##.....##.####.########.##....##.########.....###...
    .##.....##..##..##.....##..##..##.......###...##.##.....##...##.##..
    .##.....##..##..##.....##..##..##.......####..##.##.....##..##...##.
    .##.....##..##..##.....##..##..######...##.##.##.##.....##.##.....##
    ..##...##...##...##...##...##..##.......##..####.##.....##.#########
    ...##.##....##....##.##....##..##.......##...###.##.....##.##.....##
    ....###....####....###....####.########.##....##.########..##.....##
    */}
    <Modal isOpen={Modal23} toggle={() => setModal23(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{backgroundColor: "White" ,borderRadius: "15px", border: "3px solid #979797"}}>
            <div className="modal-header">
                <h3> </h3>
                <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={() => setModal23(false)}
                >
                <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
                </button>
            </div>
            <ModalBody>
                <h1 className="text-center title">
                    <b style={{color : "#1c6e38"}}>AFECTACIÓN A </b>VIVIENDA FAMILIAR<br></br>
                </h1>
                <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Esta protección la ordena la ley sobre la vivienda que habita la pareja casada 
                    o en unión marital durante al menos dos años y se llama comúnmente, la ley de 
                    la doble firma, porque para vender el inmueble protegido se requiere la firma de los dos.</b>
                </h5>
                <h5 className="description text-justify" style={{color: "Black"}}>
                <b>Además, solo puede ser embargado por el acreedor que figura en la escritura de hipoteca(Entidad financiera que realizó 
                    el préstamo para la adquisición de la vivienda, mientras haya deuda).</b>
                </h5>
                <h5 className="description text-justify" style={{color: "Black"}}>
                <b>Los cónyuges o compañeros permanentes pueden decidir no someter su vivienda a esta protección. La afectación a 
                    vivienda familiar puede hacerse al momento de comprar la vivienda o tiempo después.</b>
                </h5>
                <h3 className="azul-title text-center">Requisitos:</h3>
                <ol>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Documentos de identificación de los cónyuges o compañeros permanentes.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Si el dueño de la vivienda es uno de los cónyuges, para protegerla se necesita que firme el otro cónyuge o compañero permanente. </b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Certificado de libertad de la vivienda.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Para cancelar la afectación a vivienda familiar, se deben cumplir estos requisitos.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Documentos de identificación de los cónyuges o compañeros permanentes.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Escritura pública donde aparece la protección de la vivienda.</b>
                        </h5>
                    </li>
                    <li>
                        <h5 className="description text-justify" style={{color: "Black"}}>
                        <b>Defunción.</b>
                        </h5>
                    </li>
                </ol>
                <h4 className="description text-justify" style={{color: "Black"}}>
                    <b>Tiempo estimado de entrega: </b>Consulte con el notario, dado que el acto depende de la revisión que hace éste. llamada "control de legalidad"
                </h4>
                <h4 className="description text-justify" style={{color: "Black"}}>
                    <b>Costo: </b>Sí. Según la tarifa notarial.
                </h4>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="azul"
                    type="button"
                    onClick={() => setModal23(false)}
                    className="text-center"
                >
                    <b style={{fontSize: "17px"}}>Cerrar</b>
                </Button>
            </ModalFooter>
        </div>
    </Modal>
    {/*
    .########.....###....########.########..####.##.....##..#######..##....##.####..#######.
    .##.....##...##.##......##....##.....##..##..###...###.##.....##.###...##..##..##.....##
    .##.....##..##...##.....##....##.....##..##..####.####.##.....##.####..##..##..##.....##
    .########..##.....##....##....########...##..##.###.##.##.....##.##.##.##..##..##.....##
    .##........#########....##....##...##....##..##.....##.##.....##.##..####..##..##.....##
    .##........##.....##....##....##....##...##..##.....##.##.....##.##...###..##..##.....##
    .##........##.....##....##....##.....##.####.##.....##..#######..##....##.####..#######.
    */}
    <Modal isOpen={Modal5} toggle={() => setModal5(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{backgroundColor: "White" ,borderRadius: "15px", border: "3px solid #979797"}}>
            <div className="modal-header">
                <h3> </h3>
                <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={() => setModal5(false)}
                >
                    <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
                </button>
        </div>
        <ModalBody>
            <h1 className="text-center title">
                <b style={{color : "#1c6e38"}}>PATRIMONIO DE </b>FAMILIA INEMBARGABLE<br></br>
            </h1>
            <h5 className="description text-justify" style={{color: "Black"}}>
                <b>Es una clase especial de protección de la vivienda de una familia, que impide el embargo que pueda hacerle un
                        acreedor al dueño de la propiedad. Además, evita que el propietario ponga en peligro el inmueble de 
                        la familia por un mal negocio. Si en el momento de la compra no se hizo esta protección, puede hacerse 
                        en cualquier momento. Solo puede ser embargado por el acreedor que figura en la escritura de hipoteca, 
                        esto es, por la entidad financiera o por la persona que realizó el préstamo para la adquisición de la vivienda, 
                        mientras haya deuda en mora.</b>
            </h5>
            <h5 className="description text-justify" style={{color: "Black"}}>
            <b>Cuando es una vivienda de interés social, es obligatorio por ley y se debe hacer en la misma escritura pública de compra de la vivienda.</b>
            </h5>
            <h3 className="azul-title text-center">Requisitos:</h3>
            <ol>
                <li>
                    <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Documentos de identificación de los cónyuges o compañeros permanentes.</b>
                    </h5>
                </li>
                <li>
                    <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Certificado de la Oficina de Registro de Instrumentos Públicos sobre la vivienda</b>
                    </h5>
                </li>
                <li>
                    <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Copia auténtica del registro civil de los hijos menores que se benefician de la protección. Se beneficia también de esta protección el cónyuge o compañero permanente que no es dueño de la vivienda.</b>
                    </h5>
                </li>
                <li>
                    <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Una vez hecha la escritura pública de patrimonio de familia inembargable, notario entrega la copia auténtica para llevarla a la Oficina de Registro d Instrumentos Públicos, dentro de 
                        los noventa días siguientes a la fecha de la escritura. Olvidar este plazo, significa volver a iniciar el trámite.</b>
                    </h5>
                </li>
            </ol>
            <h5 className="description text-justify" style={{color: "Black"}}>
                <b>Esta protección se puede cancelar, en la mayoría de los casos, cuando los hijos menores cumplen dieciocho años. Consulte con el notario.</b>
            </h5>
            <h4 className="description text-justify" style={{color: "Black"}}>
                <b>Tiempo estimado de entrega: </b>Mínimo ocho días hábiles mientras el notario revisa la legalidad del contrato.
            </h4>
            <h4 className="description text-justify" style={{color: "Black"}}>
                <b>Costo: </b>Sí. Según la tarifa notarial.
            </h4>
        </ModalBody>
        <ModalFooter>
            <Button
                color="azul"
                type="button"
                onClick={() => setModal5(false)}
                className="text-center"
            >
                <b style={{fontSize: "17px"}}>Cerrar</b>
            </Button>
        </ModalFooter>
        </div>
    </Modal>
    
 
    </>
  );
}

export default TramitesServicios;
