/*eslint-disable*/
import React from "react";

// reactstrap components
import {
  Col,
  Row,
  CardBody,
  Card,
  Container,
  CardTitle,
} from "reactstrap";
// core components

function PresentationHeader() {
  return (
    <>
      <Container fluid>
        <Row>
          <Col className="px-0" md="10">
            <Card
              className="card-fashion page-header-image"
              style={{
                backgroundSize: "100% 100%",
                backgroundImage:
                  "url(" + require("assets/img/Notaria/fondo.jpg") + ")",
              }}
            >
              <CardBody>
                <CardTitle className="text-left" tag="div">
                  <div className="rellax-text-container rellax-text">
                    <h1 className="title" data-rellax-speed="-1" 
                      style={{marginTop: "-300px", 
                              fontSize: "500%", 
                            }}>
                      Notaría 21 de Cali
                    </h1>
                    <h2 className="title" data-rellax-speed="-1">
                      SERVICIOS NOTARIALES
                    </h2>
                  </div>
                </CardTitle>
              </CardBody>
            </Card>
          </Col>
          <Col className="px-0" md="2">
            <Card className="card-fashion arrow-left" style={{marginTop: "150px"}}>
              <CardTitle tag="div">
                <h5>
                  <br></br> 
                  <br></br>
                  <b style={{color:"#0a006c"}}>Contador de Visitas</b>
                  <br></br>             
                  <a href="https://www.contadorvisitasgratis.com" title="contador de visitas wordpress">
                    <img src="https://counter8.optistats.ovh/private/contadorvisitasgratis.php?c=cm2fg66hepcqq683prbd5zzkxtchlqnd" border="0" title="contador de visitas wordpress" alt="contador de visitas wordpress"/>
                  </a>  
                  <br></br> 
                  <i className="now-ui-icons tech_watch-time" style={{fontSize: "25px", color:"#bd0b0e"}}></i>
                  <br></br>
                  <b style={{color:"#bd0b0e"}}>Horario</b>
                  <br></br>
                  <br></br>
                  <b style={{color:"#0a006c"}}>Lunes a Viernes</b>
                  <br></br>
                    8:00am - 1:00pm
                  <br></br>
                    2:00pm - 5:00pm
                  <br></br>
                  <br></br>
                  <b style={{color:"#0a006c"}}>Sábados</b>
                  <br></br>
                    9:00am - 1:00pm
                  <br></br>
                </h5>
              </CardTitle>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PresentationHeader;
